import React from 'react';
import './add.scss';
const AddContainer = () => {
  return (
    <div className="add-container">
      <div className="top2">
        <h1>Add User</h1>
        <div className="item">
          <span>Name:</span>
          <input type="text" placeholder="Enter Your Name" />
        </div>
        <div className="item">
          <span>Username:</span>
          <input type="text" placeholder="Enter Your Name" />
        </div>
        <div className="middle">
          <div>
            <span>Email:</span>
            <input type="text" placeholder="Enter Your Name" />
          </div>
          <div className="">
            <span>Phone:</span>
            <input type="text" placeholder="Enter Your Name" />
          </div>
        </div>
        <div className="item">
          <span>Password:</span>
          <input type="password" placeholder="Enter Your Name" />
        </div>
        <div className="item">
          <span>Select Role:</span>
          <select name="cars">
            <option value="">Select Role</option>
            <option value="">Editor</option>
            <option value="">Reader</option>
          </select>
        </div>
      </div>
      <div className="bottom">
        <div className="stats">
          <span>Status:</span>
          <div className="select">
            <div>
              <input type="checkbox" name="active" id="" />
              <span>Active</span>
            </div>
            <div>
              <input type="checkbox" name="notActive" id="" />
              <span>Not Active</span>
            </div>
          </div>
        </div>
        <button>Save</button>
      </div>
    </div>
  );
};

export default AddContainer;
