import React from "react";
import SideBar from "../../../Components/SideBar/SideBar";
import Header from "../../../Components/Header/Header";
import "./location.scss";
import { AiOutlineSearch } from "react-icons/ai";
import { Link } from "react-router-dom";
import LocationTable from "./LocationTable";
import { useDispatch, useSelector } from "react-redux";
import { getAllLocation } from "../../../api/LocationApi/Location";
import { useEffect } from "react";
const Location = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllLocation());
  }, [dispatch]);
  const locationData = useSelector(
    (state) => state?.location?.location?.data || []
  );
  // console.log(locationData);
  return (
    <div className="locationContainer">
      <SideBar />
      <div className="locationWrapper">
        <Header title={"Location"} />
        <div className="location">
          <div className="locationBody">
            <h1>All</h1>
            <div className="aboutLeftContainer">
              <div className="aboutInput">
                <input type="text" placeholder="Search for Anything..." />
                <AiOutlineSearch />
              </div>
              <Link to={"/AddLocation"}>
                <button>Add New</button>
              </Link>
            </div>
          </div>
          {locationData.length > 0 && (
            <LocationTable locationData={locationData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Location;
