import React, { useState } from 'react';
import './settingInfo.scss';
import Warning from '../../../../Components/warning/Warning';
const SettingInfo = () => {
  const [showWarning, setShowWarning] = useState(false);
  const info = [{ name: '#' }, { name: 'Key' }, { name: 'Value' }];
  const infoContent = [
    {
      id: 1,
      key: 'key',
      value:
        'consequat sunt nostrud amet ullamco est sit aliqua dolor do amet sint. Velit officia consequ',
    },
    {
      id: 2,
      key: 'key',
      value:
        'consequat sunt nostrud amet ullamco est sit aliqua dolor do amet sint. Velit officia consequ',
    },
    {
      id: 3,
      key: 'key',
      value:
        'consequat sunt nostrud amet ullamco est sit aliqua dolor do amet sint. Velit officia consequ',
    },
  ];

  return (
    <div className="info-wrapper">
      <div className="infoTop">
        <div className="settingHeader">
          {info.map((item, i) => (
            <p key={i}> {item.name}</p>
          ))}
        </div>
        <div className="settingBottom">
          {infoContent.map((item, i) => (
            <div key={i}>
              <div>
                <p>{item.id}</p>
                <p>{item.key}</p>
                <p>{item.value}</p>
              </div>
              <button onClick={() => setShowWarning(!showWarning)}>
                Delete
              </button>
            </div>
          ))}
        </div>
      </div>
      <Warning showWarning={showWarning} setShowWarning={setShowWarning} />
    </div>
  );
};

export default SettingInfo;
