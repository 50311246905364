import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../Consts";

export const getAboutUs = createAsyncThunk(
  "about/getAbout",
  async (_, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/AboutUs`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      // console.log(`${BASE_URL}/AboutUs`);
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const addAbout = createAsyncThunk(
  "about/addAbout",
  async (newData, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(`${BASE_URL}/AboutUs/store`, newData, {
        headers: {
          "Content-Type": "application/json,multipart/form-data",
          Accept: "application/json",
          authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateAbout = createAsyncThunk(
  "about/updateAbout",
  async ({ id, newData }, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${BASE_URL}/AboutUs/update/${id}`,
        newData,
        {
          headers: {
            "Content-Type": "application/json,multipart/form-data",
            Accept: "application/json",
            authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response.data);
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);
export const deleteAbout = createAsyncThunk(
  "about/deleteAbout",
  async (id, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `${BASE_URL}/AboutUs/delete/${id}`,

        {
          headers: {
            "Content-Type": "application/json,multipart/form-data",
            Accept: "application/json",
            authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response.data);
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);
export const getAboutByid = createAsyncThunk(
  "about/getAboutByID",
  async (id, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    console.log(id);
    try {
      const response = await axios.get(`${BASE_URL}/AboutUs/edit/${id}`, {
        headers: {
          "Content-Type": "application/json,multipart/form-data",
          Accept: "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data);
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);
const AboutStore = createSlice({
  name: "about",
  initialState: { about: [], loading: false, error: null, singleAbout: [] },
  extraReducers: {
    [getAboutUs.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getAboutUs.fulfilled]: (state, action) => {
      state.about = action.payload;
      state.loading = false;
    },
    [getAboutUs.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getAboutByid.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getAboutByid.fulfilled]: (state, action) => {
      state.singleAbout = action.payload;
      state.loading = false;
    },
    [getAboutByid.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateAbout.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [updateAbout.fulfilled]: (state, action) => {
      state.about = action.payload;
      state.loading = false;
    },
    [updateAbout.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [addAbout.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    [addAbout.fulfilled]: (state, action) => {
      state.about = action.payload;
      state.loading = false;
      state.success = true;
    },
    [addAbout.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.success = false;
    },
    [deleteAbout.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [deleteAbout.fulfilled]: (state, action) => {
      state.about = state.about?.about?.data?.filter(
        (item) => item.id !== action.payload
      );
      state.loading = false;
    },
    [deleteAbout.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});
export default AboutStore.reducer;
