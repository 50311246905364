import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../Consts';
export const getPartner = createAsyncThunk(
  'partner/getAllPartner',
  async (_, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/Parteners`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data);
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);
export const insertPartner = createAsyncThunk(
  'partner/addPartner',
  async (newData, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${BASE_URL}/Parteners/store`,
        newData,
        {
          headers: {
            'Content-Type': 'application/json,multipart/form-data',
            Accept: 'application/json',

            authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response.data);
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error.message);
    }
  }
);
export const UpdatePartnerFun = createAsyncThunk(
  'partner/updatePartner',
  async ({ id, newData }, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${BASE_URL}/Parteners/update/${id}`,
        newData,
        {
          headers: {
            'Content-Type': 'application/json,multipart/form-data',
            Accept: 'application/json',

            authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response);
      return response.data; // Return the response data
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);
export const deletePartner = createAsyncThunk(
  'partner/deletePartner',
  async (id, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `${BASE_URL}/Parteners/delete/${id}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response);
      return response; // Return the ID of the deleted news item
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);
const PartnerStore = createSlice({
  name: 'partners',
  initialState: { partner: [], loading: false, error: null },
  extraReducers: {
    [getPartner.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getPartner.fulfilled]: (state, action) => {
      state.partner = action.payload;
      state.loading = false;
    },
    [getPartner.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [UpdatePartnerFun.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [UpdatePartnerFun.fulfilled]: (state, action) => {
      // Find the index of the updated news item in the news array
      const updatedNewsIndex = state.partner.partner.data.findIndex(
        (newsItem) => newsItem.id === action.payload.id
      );
      // console.log(updatedNewsIndex);
      // Replace the old news item with the updated one
      state.partner[updatedNewsIndex] = action.payload;
      state.loading = false;
    },
    [UpdatePartnerFun.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [insertPartner.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [insertPartner.fulfilled]: (state, action) => {
      state.partner = action.payload;
      state.loading = false;
    },
    [insertPartner.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [deletePartner.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [deletePartner.fulfilled]: (state, action) => {
      // Remove the deleted news item from the news array
      state.partner = state?.partner?.partner?.data.filter(
        (newsItem) => newsItem.id !== action.payload
      );
      state.loading = false;
    },
    [deletePartner.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});
export default PartnerStore.reducer;
