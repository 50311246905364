import React from "react";
import SideBar from "../../../Components/SideBar/SideBar";
import "./email.scss";
import Header from "../../../Components/Header/Header";
import EmailBody from "./EmailBody/EmailBody";
const Email = () => {
  return (
    <div className="email-container">
      <SideBar />
      <div>
        <Header title={"Email Configuration"} />
        {/* <EmailBody /> */}
      </div>
    </div>
  );
};

export default Email;
