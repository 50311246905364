import React, { useState, useEffect } from "react";
import ProgressFullBar from "../ProgressFullBar/ProgressFullBar";
import "./ProductOne.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAllCategories } from "../../../../../api/CategoryApi/CategorySlice.js";
import { getallLang } from "../../../../../api/LanguageApi/Lang";
import Select from "react-select";
import { Countries } from "../../../../../api/CountryApi";

const AddProductOne = ({
  onNext,
  name,
  setName,
  seo,
  setSeo,
  category,
  setCategory,
  setDesc,
  desc,
  modelNum,
  setModelNum,
  isActive,
  setIsActive,
  featured,
  setFeatured,
  product,
  selectedOption,
  setSelectedOption,
  productLocation,
  setProductLocation,
}) => {
  const dispatch = useDispatch();
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);

  const data = useSelector((state) => state?.category?.category?.data);

  const handleChange = (event) => {
    setIsChanged(true);
    const selectedCategoryId = event.target.value;
    setCategory([selectedCategoryId]);
  };

  const handleChange2 = (event) => {
    const selectedCountry = event.target.value;
    setProductLocation(selectedCountry);
  };
  const langs = useSelector((state) => state?.Lang?.lang?.langs);
  const options = langs?.map((language) => ({
    value: language.id,
    label: language.name,
  }));
  console.log(productLocation, "===========");
  useEffect(() => {
    dispatch(getallLang());
  }, [dispatch]);
  return (
    <div>
      <div>
        <ProgressFullBar showImage={false} showSpec={false} completed={25} />
      </div>
      <div className="AddProductForm">
        <div className="FormName">
          <span>Product Name</span>
          <input
            type="text"
            defaultValue={product?.name || ""}
            placeholder="Enter Product Name"
            onChange={(e) => {
              setIsChanged(true);
              setName(e.target.value);
            }}
          />
        </div>
        <div className="selectForm">
          <div className="selectForm">
            <label htmlFor="category">Category</label>
            <select
              id="category"
              defaultValue={product?.categories[0]?.id}
              onChange={handleChange}
            >
              {data?.map((item, i) => (
                <option key={i} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="FormName">
          <span>Description</span>
          <input
            type="text"
            placeholder="Enter Model description"
            onChange={(e) => {
              setIsChanged(true);
              setDesc(e.target.value);
            }}
            defaultValue={product?.description}
          />
        </div>
        <div className="selectForm">
          <div className="selectForm">
            <label htmlFor="Country">Country</label>
            <select
              id="Country"
              value={productLocation}
              multiple={false}
              onChange={handleChange2}
            >
              <option>Select Country</option>
              {Countries?.map((item, i) => (
                <option key={i} value={item?.code}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="FormName">
          <span>Enter Model_no</span>
          <input
            type="text"
            placeholder="Enter Model Number"
            onChange={(e) => setModelNum(e.target.value)}
            defaultValue={product?.model_no}
          />
        </div>
        {/* <div className="ProductCheck">
          <div>
            <input
              type="radio"
              name="status"
              id="active"
              defaultChecked={product?.is_active}
              onChange={handleActiveChange}
            />
            <label htmlFor="active">Is_Active</label>
          </div> */}
        {/* <div>
            <input
              type="radio"
              name="status"
              id="featured"
              defaultChecked={product?.featured}
              onChange={handleFeaturedChange}
            />
            <label htmlFor="featured">Feature</label>
          </div> */}
        {/* </div> */}
        <div className="FormSeo">
          <span>Product SEO</span>
          <input
            type="text"
            defaultValue={product?.seo || ""}
            placeholder="Enter Product SEO"
            onChange={(e) => {
              setSeo(e.target.value);
            }}
          />
        </div>
        <div style={{ width: "10vw" }}>
          <p>Language</p>
          <Select
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            value={selectedOption}
            options={options}
          />
        </div>
        <div className="AddProductBtn">
          <button onClick={onNext}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default AddProductOne;
