import React from 'react';
import './settings.scss';
import SideBar from '../../../Components/SideBar/SideBar';
import Header from '../../../Components/Header/Header';
import Top from '../../../Components/Top/Top';
import SettingInfo from './Setting/SettingInfo';
const Settings = () => {
  return (
    <div className="settings-wrapper">
      <SideBar />
      <div className="setting-container">
        <Header title={'Settings'} />
        <div className="body">
          <Top title={'Settings'} />
          <SettingInfo />
        </div>
      </div>
    </div>
  );
};

export default Settings;
