import React, { useState } from "react";
import "./ProductThree.scss";
import ProgressFullBar from "../ProgressFullBar/ProgressFullBar";
import BasicTable from "../../../../../Components/Table/Table";
import { useDispatch } from "react-redux";
import { addProduct } from "../../../../../api/ProductApi/productSlice";
import { updateProduct } from "../../../../../api/ProductApi/productSlice.js";
import { useNavigate } from "react-router-dom";
const ProductThree = ({ onPrev, data, setSpe, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [success, setSuccess] = useState("");

  const handleDispatchSubmit = () => {
    dispatch(addProduct(data))
      .unwrap()
      .then((res) => {
        // setError('');
        setSuccess(res.message);
      })
      .catch((err) => {
        console.log(err?.response?.data?.errors);
      });
    console.log(data);
    // navigate('/wcmProducts');
  };

  const handleSumbit = () => {
    // e.preventDefault();
    dispatch(updateProduct({ id: id, newData: data }));
    // console.log(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newSpec = { key, value };
    setSpe((prevSpe) => [...prevSpe, newSpec]);
    setKey("");
    setValue("");
  };

  return (
    <div>
      <div>
        <ProgressFullBar showImage={true} showSpec={true} completed={100} />
      </div>
      <div className="bodyContainer">
        <form action="" onSubmit={handleSubmit}>
          <div className="inputContainer">
            <span>Key</span>
            <div>
              <input
                type="text"
                placeholder="Enter Product value"
                value={key}
                onChange={(e) => setKey(e.target.value)}
              />
            </div>
          </div>
          <div className="inputContainer">
            <span>Value</span>
            <div>
              <input
                type="text"
                placeholder="Enter Product value"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
              <button type="submit" style={{ cursor: "pointer" }}>
                Add
              </button>
            </div>
          </div>
        </form>
        <div>
          <BasicTable data={data} />
        </div>
        <div className="btns">
          <button onClick={onPrev}>Back</button>
          <button onClick={id ? handleSumbit : handleDispatchSubmit}>
            Finish
          </button>
          <p style={{ fontSize: 14, fontWeight: 500, color: "green" }}>
            {success}
          </p>{" "}
        </div>
      </div>
    </div>
  );
};

export default ProductThree;
