import React, { useEffect, useState } from 'react';
import './CategoryHeader.scss';
import { AiOutlineSearch } from 'react-icons/ai';
import CategoryItem from '../CategoryItem/CategoryItem';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import {
  getAllCategories,
  DeleteCategory,
} from '../../../../api/CategoryApi/CategorySlice.js';
const CategoryHeader = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCategories());
  }, [dispatch]);
  const data = useSelector((state) => state?.category?.category?.data);
  const [value, setValue] = useState('');
  const [searchedData, setSearchedData] = useState(data);
  useEffect(() => {
    let newData = data;
    if (value) {
      newData = newData?.filter((item) => {
        const name = item?.name?.toString();
        return name?.includes(value);
      });
    }
    setSearchedData(newData);
  }, [data, value]);
  // console.log(data);
  return (
    <div className="categoryHeaderContainer">
      <div className="categoryTop">
        <div className="categoryTop2">
          <div className="categoryInput">
            <input
              type="text"
              placeholder="Search.."
              onChange={(e) => setValue(e.target.value)}
            />
            <AiOutlineSearch />
          </div>
          <Link to="/wcmCategoryAdd">
            <button>Add New</button>
          </Link>
        </div>
        {searchedData?.map((category, i) => (
          <div key={i}>
            <CategoryItem data={category} DeleteCategory={DeleteCategory} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryHeader;
