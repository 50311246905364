import React from "react";
import ProgressBar from "../../../../../Components/ProgressBar/Progress";
import { GiCheckMark } from "react-icons/gi";
import "./style.scss";
const ProgressFullBar = ({ showImage, showSpec, completed }) => {
  return (
    <div className="container2">
      <div className="bar">
        <ProgressBar bgcolor="#EA5B0C" completed={completed} />
        <div className="first">
          <div className="cir">
            <GiCheckMark />
          </div>
          <span>Add Product</span>
        </div>
        <div className="second">
          <div className={`${showImage ? "cir" : "cir2"} `}>
            {showImage && <GiCheckMark />}
          </div>
          <span>Add Images</span>
        </div>
      </div>
    </div>
  );
};

export default ProgressFullBar;
