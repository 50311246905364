import React from 'react';
import SideBar from '../../../Components/SideBar/SideBar';
import './category.scss';
import CategoryHeader from './CategoryHeader/CategoryHeader';
import Header from '../../../Components/Header/Header';
const Category = () => {
  return (
    <div className="category">
      <SideBar />
      <div>
        <Header title={'Category'} />
        <CategoryHeader />
      </div>
    </div>
  );
};

export default Category;
