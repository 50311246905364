import { ChangeEvent, useState } from 'react';
const token =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC8xOTIuMTY4LjEuMTc0OjgwOTBcL05hcmRpXC9wdWJsaWNcL2FwaVwvYXV0aFwvbG9naW4iLCJpYXQiOjE2NzY1NjA0OTYsImV4cCI6MTY3NjU2NDA5NiwibmJmIjoxNjc2NTYwNDk2LCJqdGkiOiJaMUY4T0g1UFJ0aE1zWDA1Iiwic3ViIjoxLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.Jc5Cl7uUMg9CdjKBupy8S9QLbogApRHw4Nl0x7qx2sA';
function FileUploadSingle() {
  const [file, setFile] = useState();

  const handleFileChange = (e) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleUploadClick = () => {
    if (!file) {
      return;
    }

    // 👇 Uploading the file using the fetch API to the server
    fetch('http://192.168.1.174:8090/Nardi/public/images/About Us/', file, {
      mode: 'no-cors',
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
        'content-type': file.type,
        Accept: 'application/json',
        'Accept-Encoding': 'gzip, deflate, br',
        Connection: 'keep-alive',
        'content-length': `${file.size}`, // 👈 Headers need to be a string
      },
    })
      .then((res) => res.json())
      .then((data) => console.log(data))
      .catch((err) => console.error(err));
    console.log(file);
  };

  return (
    <div>
      <input type="file" onChange={handleFileChange} name="vision_image" />

      <div>{file && `${file.name} - ${file.type}`}</div>

      <button onClick={handleUploadClick}>Upload</button>
    </div>
  );
}

export default FileUploadSingle;
