import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../Consts";
export const getAllLocation = createAsyncThunk(
  "location/getLocation",
  async (_, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/Locations`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data);
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);
export const getLocation = createAsyncThunk(
  "location/getLocationSingle",
  async (id, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/Locations/edit/${id}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data);
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);
export const insertLocation = createAsyncThunk(
  "location/addLocation",
  async (newData, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${BASE_URL}/Locations/store`,
        newData,
        {
          headers: {
            "Content-Type": "application/json,multipart/form-data",
            Accept: "application/json",
            authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response.data);
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);
export const UpdateLocationApi = createAsyncThunk(
  "location/addLocation",
  async ({ id, newData }, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${BASE_URL}/Locations/update/${id}`,
        newData,
        {
          headers: {
            "Content-Type": "application/json,multipart/form-data",
            Accept: "application/json",
            "Accept-Encoding": "gzip, deflate, br",
            Connection: "keep-alive",
            authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response);
      return response.data; // Return the response data
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);
export const deleteLocation = createAsyncThunk(
  "location/deleteLocation",
  async (id, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `${BASE_URL}/Locations/delete/${id}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response);
      return id; // Return the ID of the deleted news item
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);

const LocationSlice = createSlice({
  name: "location",
  initialState: {
    location: [],
    loading: false,
    error: null,
    singleLocation: [],
  },
  extraReducers: {
    [getAllLocation.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getAllLocation.fulfilled]: (state, action) => {
      state.location = action.payload;
      state.loading = false;
    },
    [getAllLocation.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getLocation.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getLocation.fulfilled]: (state, action) => {
      state.singleLocation = action.payload;
      state.loading = false;
    },
    [getLocation.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    [deleteLocation.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [deleteLocation.fulfilled]: (state, action) => {
      // Remove the deleted news item from the news array
      state.location = state.location.filter(
        (locationItem) => locationItem.id !== action.payload
      );
      state.loading = false;
    },
    [deleteLocation.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [insertLocation.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [insertLocation.fulfilled]: (state, action) => {
      state.location = action.payload;
      state.loading = false;
    },
    [insertLocation.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [UpdateLocationApi.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    // [UpdateLocationApi.fulfilled]: (state, action) => {
    //   // Find the index of the updated news item in the news array
    //   const updatedLocationIndex = state?.location?.location?.data.findIndex(
    //     (locationItem) => locationItem.id === action.payload.id
    //   );
    //   // console.log(updatedLocationIndex);
    //   // Replace the old news item with the updated one
    //   state.location[updatedLocationIndex] = action.payload;
    //   state.loading = false;
    // },
    // [UpdateLocationApi.rejected]: (state, action) => {
    //   state.error = action.payload;
    //   state.loading = false;
    // },
  },
});
export default LocationSlice.reducer;
