import React from 'react';
import SideBar from '../../../Components/SideBar/SideBar';
import Header from '../../../Components/Header/Header';
import Table from './Table/Table';
import './users.scss';
const Users = () => {
  return (
    <div className="user-container">
      <SideBar />
      <div>
        <Header title="User" />
        <Table />
      </div>
    </div>
  );
};

export default Users;
