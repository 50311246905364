import React, { useEffect, useState } from 'react';
import SideBar from '../../../Components/SideBar/SideBar';
import Header from '../../../Components/Header/Header';
import './contact.scss';
import { AiOutlineSearch } from 'react-icons/ai';
import ContactTable from './ContactTable';
import { useDispatch, useSelector } from 'react-redux';
import { getAllContacts } from '../../../api/ContactUsSlice/ContctUsSlice.js';
const Contact = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllContacts());
  }, [dispatch]);

  const contactData = useSelector(
    (state) => state?.contactUs?.contactUs?.data || []
  );
  // console.log(contactData);
  const [value, setValue] = useState('');
  const [searchedData, setSearchedData] = useState(contactData);
  useEffect(() => {
    let newData = contactData;
    if (value) {
      newData = newData?.filter((item) => {
        // console.log(item)
        const name = item?.phone?.toString();
        return name?.includes(value);
      });
    }
    setSearchedData(newData);
  }, [contactData, value]);
  return (
    <div className="contactWrapper">
      <SideBar />
      <div className="contactContainer">
        <Header title={'Contact Us'} />
        <div className="contactBody">
          <div className="contactHeader">
            <h1>Contact Us</h1>
            <div className="contactLeft">
              <input
                type="text"
                placeholder="Search For AnyThing...."
                onChange={(e) => setValue(e.target.value)}
              />
              <AiOutlineSearch />
            </div>
          </div>
          <div className="contactBottom">
            <ContactTable data={searchedData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
