import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getPermissions = createAsyncThunk(
  'roles/getPermissions',
  async (_, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const { token } = getState().auth;
    try {
      const res = await fetch(
        'http://192.168.1.174:8090/Nardi/public/api/Dashboard/Permissoin',
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const AddPermission = createAsyncThunk(
  'role/addRole',
  async (data, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const { token } = getState().auth;
    try {
      const res = await fetch(
        'http://192.168.1.174:8090/Nardi/public/api/Dashboard/Roles/store',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
            authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );
      const dataAddPermission = res.json();
      console.log(dataAddPermission);
      return dataAddPermission;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const primissionSlice = createSlice({
  name: 'permissions',
  initialState: { permissions: [], loading: false, error: null },
  extraReducers: {
    // Get permissions
    [getPermissions.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getPermissions.fulfilled]: (state, action) => {
      state.permissions = action.payload;
      state.loading = false;
    },
    [getPermissions.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    // Get permissions
    [AddPermission.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [AddPermission.fulfilled]: (state, action) => {
      state.permissions = [...state.permissions.data, action.payload];
      state.loading = false;
    },
    [AddPermission.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});
export default primissionSlice.reducer;
