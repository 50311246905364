import React, { useEffect, useState } from 'react';
import SideBar from '../../../Components/SideBar/SideBar';
import Header from '../../../Components/Header/Header';
import './News.scss';
import { AiOutlineSearch } from 'react-icons/ai';
import NewsTable from './Table/NewsTable';
import { useDispatch, useSelector } from 'react-redux';
import { getAllNews } from '../../../api/newsApi/New.js';
import { Link } from 'react-router-dom';
const News = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllNews());
  }, [dispatch]);
  const data = useSelector((state) => state?.news?.new?.data || []);
  // console.log(data);
  const [value, setValue] = useState('');
  const [searchedData, setSearchedData] = useState(data);
  useEffect(() => {
    let newData = data;
    if (value) {
      newData = newData?.filter((item) => {
        const content = item?.content?.toString();
        const title = item?.title?.toString();
        return title?.includes(value) || content?.includes(value);
        // console.log(item);
      });
    }
    setSearchedData(newData);
  }, [data, value]);
  return (
    <div className="newContainer">
      <SideBar />
      <div>
        <Header title={'News'} />
        <div className="newWrapper">
          <div className="aboutBody">
            <h1>All</h1>
            <div className="aboutLeftContainer">
              <div className="aboutInput">
                <input
                  type="text"
                  placeholder="Search for Anything..."
                  onChange={(e) => setValue(e.target.value)}
                />
                <AiOutlineSearch />
              </div>
              <Link to={'/addNews'}>
                <button>Add New</button>
              </Link>
            </div>
          </div>
          <NewsTable data={searchedData} />
        </div>
      </div>
    </div>
  );
};

export default News;
