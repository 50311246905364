import React, { useState, useEffect } from "react";
import "./Category.scss";
import SideBar from "../../../../Components/SideBar/SideBar";
import Header from "../../../../Components/Header/Header";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { useAlert } from "react-alert";
import {
  getCategory,
  updateCategory,
} from "../../../../api/CategoryApi/CategorySlice.js";
import { useSelector } from "react-redux";
import { getAllCategories } from "../../../../api/CategoryApi/CategorySlice.js";
import { useParams } from "react-router-dom";
import { getallLang } from "../../../../api/LanguageApi/Lang";
const CategoryUpdate = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [seo, setSeo] = useState("");
  const [img, setimg] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const alert = useAlert();
  const [cat, setCat] = useState("");
  const { id } = useParams();
  const data2 = useSelector((state) => state?.category?.oneCatgory?.category);
  useEffect(() => {
    dispatch(getCategory(id));
    dispatch(getAllCategories());
    dispatch(getallLang());
    if (data2) {
      setName(data2?.name);
      // setimg(data2?.image);
      setCat(data2?.parent_id);
      setSelectedOption({ value: data2?.lang?.id, label: data2?.lang?.name });
    }
  }, [dispatch]);
  const data = useSelector((state) => state?.category?.category?.data);
  const loading = useSelector((state) => state?.category?.loading);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: name,
      seo: seo,
      image: img,
      parent_id: cat,
      language_id: selectedOption?.value,
    };
    // console.log(data);
    dispatch(updateCategory({ id: id, newData: data }))
      .unwrap()
      .then((originalPromiseResult) => {
        alert.show("Sucessful Updated");
        // console.log(originalPromiseResult);
        // dispatch(getallLang());
      })
      .catch((rejectedValueOrSerializedError) => {
        alert.show(rejectedValueOrSerializedError?.response?.data?.message);

        console.log(rejectedValueOrSerializedError);
      });

    // console.log(data);
  };

  const langs = useSelector((state) => state?.Lang?.lang?.langs);
  //   console.log(langs, "----");
  const options = langs?.map((language) => ({
    value: language.id,
    label: language.name,
  }));
  return (
    <div className="categoryAdd-container">
      <SideBar />
      <div className="categoryAdd-right">
        <Header title={"Category"} />
        <form className="categoryAdd-Bottom" onSubmit={handleSubmit}>
          <div>
            <p>Add Category</p>
            <div className="inputCategory">
              <span>Name</span>
              <input
                type="text"
                value={name}
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="inputCategory">
              <span>SEO</span>
              <input
                type="text"
                value={seo}
                placeholder="SEO"
                onChange={(e) => setSeo(e.target.value)}
              />
            </div>
            <div className="file">
              <input
                type="file"
                placeholder="Choose an image"
                alt="Category-alt"
                // value={img}
                onChange={(e) => setimg(e.target.files[0])}
              />
            </div>
          </div>
          <div className="categorySelect">
            <select onChange={(e) => setCat(e.target.value)}>
              <option value={cat}>Select Parent Category</option>
              {data?.map((item, i) => (
                <option value={item.id} key={i}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div style={{ width: "22vw" }}>
            <Select
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={options}
              value={selectedOption}
            />
          </div>
          <div className="categoryBtn">
            <button>Save</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CategoryUpdate;
