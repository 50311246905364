import React, { useEffect, useState } from 'react';
import SideBar from '../../../Components/SideBar/SideBar';
import Header from '../../../Components/Header/Header';
import './product.scss';
import TableHeader from '../../Admin/Users/Table/TableHeader/TableHeader';
import ProductTable from './ProductTable/ProductTable';
import { useDispatch, useSelector } from 'react-redux';
import { getAllProducts } from '../../../api/ProductApi/productSlice.js';
const Products = () => {
  // const [dataFetched, setDataFetched] = useState();
  const [err, setError] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProducts())
      .unwrap()
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        setError(err.response.status);
      });
  }, [dispatch]);
  const products = useSelector((state) => state?.products?.products || []);
  const data = products?.data;
  // console.log(data);

  return (
    <div className="productContainer">
      <SideBar />
      <div>
        <Header title={'Product'} />
        <div className="productWrapper">
          <TableHeader title={'Products'} link={'/wcmProductsAdd'} />
          <div>
            {err === 404 ? <h1>No Data</h1> : <ProductTable data={data} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
