import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { Link } from "react-router-dom";
import DeleteNew from "../DeleteNew/DeleteNew";
import { useState } from "react";
import { BASE_URL_IMAGE } from "../../../../Consts";

export default function BasicTable({ data }) {
  const [showWarning, setShowWarning] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const active = <input type="checkbox" checked disabled />;
  const unActive = <input type="checkbox" disabled />;
  const handleDelete = (id) => {
    setSelectedRow(id);

    setShowWarning(!showWarning);
  };
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Content</TableCell>
              <TableCell>Archived</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Language</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, idx) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {++idx}
                </TableCell>
                <TableCell>
                  <img
                    style={{
                      width: "96px",
                      height: "90px",
                      objectFit: "contain",
                    }}
                    src={`${BASE_URL_IMAGE}/${row.image}`}
                    alt="images"
                  />
                </TableCell>
                <TableCell>{row.title}</TableCell>
                <TableCell>{row.content}</TableCell>
                <TableCell align="center">
                  {row.archieved ? active : unActive}
                </TableCell>
                {/* <TableCell>{row.update}</TableCell> */}
                <TableCell>{row.location}</TableCell>
                {/* {console.log(row)} */}
                <TableCell>{row?.lang?.name}</TableCell>
                <TableCell>
                  <Link to={`/updateNews/${row.id}`}>
                    <button
                      style={{
                        width: "108px",
                        height: "37px",
                        background: "#4FCF3B",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        borderRadius: "6px",
                        outline: "none",
                        border: "none",
                        color: "white",
                        cursor: "pointer",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                    >
                      Update
                    </button>
                  </Link>
                </TableCell>
                <TableCell>
                  {" "}
                  <button
                    onClick={(e) => handleDelete(row.id)}
                    style={{
                      width: "108px",
                      height: "37px",
                      background: "#E30000",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "6px",
                      outline: "none",
                      border: "none",
                      color: "white",
                      fontWeight: "400",
                      cursor: "pointer",
                      fontSize: "16px",
                    }}
                  >
                    Delete
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showWarning && (
        <DeleteNew
          id={selectedRow}
          showWarning={showWarning}
          setShowWarning={setShowWarning}
        />
      )}
    </>
  );
}
