import React from 'react';
import './edit.scss';
import SideBar from '../../../../../Components/SideBar/SideBar';
import Header from '../../../../../Components/Header/Header';
import EditContainer from './EditContainer';
const EditUser = () => {
  return (
    <div className="add">
      <SideBar />
      <div>
        <Header />
        <EditContainer title="User" />
      </div>
    </div>
  );
};

export default EditUser;
