import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../Consts";

export const getAllProducts = createAsyncThunk(
  "product/getAllProducts",
  async (_, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/Products`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
// export const getAllProductCategory = createAsyncThunk(
//   'product/getAllProducts',
//   async (_, thunkApi) => {
//     const { rejectWithValue, getState } = thunkApi;
// const token = localStorage.getItem("token");
//     try {
//       const response = await axios.get(
//         'http://192.168.1.174:8090/Nardi/public/api/Dashboard/Products',
//         {
//           headers: {
//             authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       return response.data;
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );

export const addProduct = createAsyncThunk(
  "product/addProduct",
  async (productData, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const res = await axios.post(`${BASE_URL}/Products/store`, productData, {
        headers: {
          "Content-Type": "application/json,multipart/form-data",
          Accept: "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      // console.log(res.data);
      return res.data;
    } catch (err) {
      // console.log(err);

      return rejectWithValue(err);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "product/deleteProduct",
  async (id, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const res = await fetch(`${BASE_URL}/Products/delete/${id}`, {
        method: "DELETE",
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      return { id };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const deleteProductImage = createAsyncThunk(
  "product/deleteProductImage",
  async (id, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const res = await fetch(`${BASE_URL}/Products/delete-image/${id}`, {
        method: "DELETE",
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      return { id };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "product/updateProduct",
  async ({ id, productData }, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const res = await axios.post(
        `${BASE_URL}/Products/update/${id}`,
        productData,
        {
          headers: {
            "Content-Type": "application/json,multipart/form-data",
            Accept: "application/json",
            authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(res);
      return res.data; // Return the response data
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const getProduct = createAsyncThunk(
  "product/getAllProduct",
  async (id, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/Products/edit/${id}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
const ProductStore = createSlice({
  name: "products",
  initialState: { products: [], loading: false, error: null },
  extraReducers: {
    [getAllProducts.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getAllProducts.fulfilled]: (state, action) => {
      state.products = action.payload;
      state.loading = false;
    },
    [getAllProducts.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [addProduct.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [addProduct.fulfilled]: (state, action) => {
      state.products = action.payload;
      state.loading = false;
    },
    [addProduct.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [deleteProduct.fulfilled]: (state, action) => {
      const productId = action.payload;
      state.products = state?.products?.products?.filter(
        (product) => product.id !== productId
      );
    },
    [deleteProduct.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteProduct.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [deleteProduct.rejected]: (state, action) => {
      state.loading = false;
    },
    [updateProduct.fulfilled]: (state, action) => {
      const updatedProduct = action.payload;
      const productIndex = state?.products?.products?.findIndex(
        (product) => product.id === updatedProduct.id
      );
      if (productIndex !== -1) {
        state.products[productIndex] = updatedProduct;
      }
    },
    [getProduct.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getProduct.fulfilled]: (state, action) => {
      state.products = action.payload;
      state.loading = false;
    },
    [getProduct.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});
export default ProductStore.reducer;
