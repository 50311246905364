import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import DeleteEmail from './DeleteEmail/DeleteEmail';

const EmailTable = ({ data }) => {
  const [showWarning, setShowWarning] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const handleDelete = (id) => {
    setSelectedRow(id);
    setShowWarning(!showWarning);
  };
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ width: '100%' }}>
            <TableRow sx={{ width: '100%' }}>
              <TableCell>#</TableCell>
              <TableCell>Email</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, idx) => (
              <TableRow
                key={idx}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {++idx}
                </TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>
                  <button
                    onClick={(e) => handleDelete(row.id)}
                    style={{
                      width: '108px',
                      height: '37px',
                      background: '#E30000',
                      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                      borderRadius: '6px',
                      outline: 'none',
                      border: 'none',
                      color: 'white',
                      fontWeight: '400',
                      fontSize: '16px',
                      cursor: 'pointer',
                    }}
                  >
                    Delete
                  </button>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showWarning && (
        <DeleteEmail
          id={selectedRow}
          showWarning={showWarning}
          setShowWarning={setShowWarning}
        />
      )}
    </>
  );
};

export default EmailTable;
