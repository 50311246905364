import React from "react";
import SideBar from "../../../Components/SideBar/SideBar";
import Header from "../../../Components/Header/Header";
import CategoryHeader from "../Categories/CategoryHeader/CategoryHeader";
import LangBody from "./LangBody/LangBody";

const Language = () => {
  return (
    <div className="category">
      <SideBar />
      <div>
        <Header title={"Language"} />
        <LangBody />
      </div>
    </div>
  );
};

export default Language;
