import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../Consts";

export const getAllProductInformations = createAsyncThunk(
  "informations/getAllProductInformations",
  async (_, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/Information`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getProductSpec = createAsyncThunk(
  "informations/getAllProductSpec",
  async (_, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/Products-Specifications`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const DeleteSpec = createAsyncThunk(
  "Spe/addProductSpe",
  async (id, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const res = await axios.delete(
        `${BASE_URL}/Products-Specifications/delete/${id}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(res);
      return id;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const addProductInfo = createAsyncThunk(
  "informations/addProductInformations",
  async (data, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const res = await axios.post(
        `${BASE_URL}/Products-Specifications/store`,
        data,
        {
          headers: {
            "Content-Type": "application/json,multipart/form-data",
            Accept: "application/json",

            authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(res.data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateProductSpe = createAsyncThunk(
  "informations/UpdateProductInformations",
  async ({ id, newData }, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    const token = localStorage.getItem("token");
    console.log(id);
    console.log(newData);
    try {
      const res = await axios.post(
        `${BASE_URL}/Products-Specifications/update/${id}`,
        newData,
        {
          headers: {
            "Content-Type": "application/json,multipart/form-data",
            Accept: "application/json",

            authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(res);
      return res.data; // Return the response data
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const productInformations = createSlice({
  name: "productInofrmations",
  initialState: {
    productInofrmations: [],
    productSpe: [],
    loading: false,
    error: null,
  },
  extraReducers: {
    [getAllProductInformations.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getAllProductInformations.fulfilled]: (state, action) => {
      state.productInofrmations = action.payload;
      state.loading = false;
    },
    [getAllProductInformations.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getProductSpec.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getProductSpec.fulfilled]: (state, action) => {
      state.productSpe = action.payload;
      state.loading = false;
    },
    [getProductSpec.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    [addProductInfo.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [addProductInfo.fulfilled]: (state, action) => {
      state.productInofrmations = action.payload;
      state.loading = false;
    },
    [addProductInfo.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [DeleteSpec.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [DeleteSpec.fulfilled]: (state, action) => {
      // Remove the deleted news item from the news array
      state.productInformation =
        state?.productInformation?.productSpe?.data?.filter(
          (categoryItem) => categoryItem?.specifications.id !== action.payload
        );
      state.loading = false;
    },
    [DeleteSpec.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateProductSpe.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [updateProductSpe.fulfilled]: (state, action) => {
      // const updatedNewsIndex = state?.productInformation?.productSpe?.data?.map(
      //   (item) =>
      //     item.specifications.findIndex(
      //       (categoryItem) => categoryItem?.id === action.payload.id
      //     )
      // );
      // console.log(updatedNewsIndex);
      // state.productInformation.productSpe.data.map((item)=>[updatedNewsIndex] =
      //   action.payload;
      state.loading = false;
    },
    [updateProductSpe.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default productInformations.reducer;
