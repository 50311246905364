import React from 'react';
import './top.scss';
import { AiOutlineSearch } from 'react-icons/ai';

const Top = () => {
  return (
    <div className="top">
      <h1>Settings</h1>
      <div className="input-wrapper">
        <input type="text" placeholder="Search for anything...." />
        <AiOutlineSearch />
      </div>
    </div>
  );
};

export default Top;
