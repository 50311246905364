import React from 'react';
import './header.scss';
import { BsArrowLeftShort } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { logOut, setCredentials } from '../../auth/authSlice';
import { useNavigate } from 'react-router-dom';

const Header = ({ title }) => {
  const dispatch = useDispatch();
  const user_name = '';
  const password = '';
  const navigate = useNavigate();
  const handleLogOut = () => {
    dispatch(logOut());
    navigate('/');
  };
  // const name = useSelector((state) => state.auth.user_name || '');
  // console.log(name);
  return (
    <div className="header-container">
      <div className="title">
        <p>{title}</p>
      </div>
      <div className="detail-container">
        <div className="mail">
        
          <p>{"ddd"}</p>
          {/* <span>michelsmith@gmail.com</span> */}
        </div>
        <div className="logoutButton">
          <button onClick={handleLogOut}>
            <div className="arrow-bg">
              <BsArrowLeftShort />
            </div>
            Log out
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
