import React from 'react';
import SideBar from '../../../../../Components/SideBar/SideBar';
import Header from '../../../../../Components/Header/Header';
import './add.scss';
import AddContainer from './AddContainer';
const AddUser = () => {
  return (
    <div className="add">
      <SideBar />
      <div>
        <Header title="User" />
        <AddContainer />
      </div>
    </div>
  );
};

export default AddUser;
