import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { apiSlice } from "../api/apiSlice";
import authReducer from "../auth/authSlice";
import roleReducer from "../RoleApi/roleSlice";
import permissionReducer from "../PremissionApi/Permission";
import categoryReducer from "../api/CategoryApi/CategorySlice";
import productReducer from "../api/ProductApi/productSlice";
import AboutReducer from "../api/About-usApi/AboutUsApiSlice";
import newsSlice from "../api/newsApi/New";
import emailSlice from "../api/EmailWcmApi/EmailApi";
import PartnerSlice from "../api/PartnerApi/PartnerSlice";
import clientSlice from "../api/ClientApi/ClientApi";
import ContactUsSlice from "../api/ContactUsSlice/ContctUsSlice";
import locationSlice from "../api/LocationApi/Location";
import productInformationSlice from "../api/productInformation/productInformationSlice";
import Lang from "../api/LanguageApi/Lang";
const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  role: roleReducer,
  permission: permissionReducer,
  category: categoryReducer,
  products: productReducer,
  about: AboutReducer,
  emails: emailSlice,
  partner: PartnerSlice,
  client: clientSlice,
  contactUs: ContactUsSlice,
  location: locationSlice,
  news: newsSlice,
  productInformation: productInformationSlice,
  Lang: Lang,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
  devTools: true,
});
