import React from "react";
import { useNavigate } from "react-router-dom";
import "../News/DeleteNew/DeleteNew.scss";
const RemoveITem = ({ showWarning, setShowWarning, handleDelete }) => {
  return (
    <div className={`${showWarning ? "warning" : "hidden"} `}>
      <div className="war-header">
        <p>Confirm Delete</p>
      </div>
      <div className="bottom">
        <div>
          <p>Are You Sure You Want Delete this?</p>
        </div>
        <div className="btns">
          <button onClick={() => handleDelete()}>Yes</button>
          <button onClick={() => setShowWarning(!showWarning)}>No</button>
        </div>
      </div>
    </div>
  );
};

export default RemoveITem;
