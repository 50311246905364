import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
import './tableHeader.scss';
const TableHeader = ({ link, title }) => {
  return (
    <div className="">
      <div className="tableHeader-container">
        <h1>{title}</h1>
        <div className="right">
          <div className="input">
            <input
              type="text"
              name=""
              id=""
              placeholder="Search for anything...."
            />
            <AiOutlineSearch />
          </div>
          <NavLink to={link}>
            <button>Add New</button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default TableHeader;
