import React, { useEffect, useState } from 'react';
import './Partners.scss';
import SideBar from '../../../Components/SideBar/SideBar';
import Header from '../../../Components/Header/Header';
import { AiOutlineSearch } from 'react-icons/ai';
import Clients from './Clients/Clients';
import PartnersPage from './Partners/PartnersPage';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPartner } from '../../../api/PartnerApi/PartnerSlice.js';
import { getCLient } from '../../../api/ClientApi/ClientApi';
const Partners = () => {
  const [active, setActive] = useState(0);
  const [error, setError] = useState('');
  const [error2, setError2] = useState('');

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPartner())
      .unwrap()
      .then((res) => {})
      .catch((err) => {
        setError(err?.response?.data.Result);
      });
    dispatch(getCLient())
      .unwrap()
      .then((res) => {})
      .catch((err) => {
        setError2(err?.response?.data.Result);
      });
  }, [dispatch]);
  const partnerData = useSelector(
    (state) => state?.partner?.partner?.data || []
  );
  const ClientData = useSelector((state) => state?.client?.CLient?.data || []);
  const [value, setValue] = useState('');
  const [searchedDataClient, setSearchedDataClient] = useState(ClientData);
  const [searchedDataPartner, setSearchedDataPartner] = useState(partnerData);
  useEffect(() => {
    let newData1 = partnerData;
    let newData2 = ClientData;

    if (value) {
      newData1 = newData1?.filter((item) => {
        const name = item?.name?.toString();
        return name?.includes(value);
      });
      newData2 = newData2?.filter((item) => {
        const name = item?.name?.toString();
        return name?.includes(value);
      });
    }
    // console.log(newData1);
    setSearchedDataPartner(newData1);
    setSearchedDataClient(newData2);
  }, [value]);
  return (
    <div className="PartnerContainer">
      <SideBar />
      <div>
        <Header title={'Partners&CLients'} />
        <div className="PartnerWrapper">
          <div className="PartnerTop">
            <div className="buttonGroup">
              <button
                onClick={() => setActive(0)}
                className={`${active ? 'white' : 'orange'}`}
              >
                Partners
              </button>
              <button
                onClick={() => setActive(1)}
                className={`${active ? 'orange' : 'white'}`}
              >
                Clients
              </button>
            </div>
            <div className="partnerTopLeft">
              <div className="partnerSearch">
                <input
                  type="text"
                  placeholder="Search for Anything....."
                  onChange={(e) => setValue(e.target.value)}
                />
                <AiOutlineSearch />
              </div>
              <Link to={`${active === 0 ? '/addPartners' : '/addClients'}`}>
                <button>Add New</button>
              </Link>
            </div>
          </div>
          <div hidden={active !== 1}>
            {error?.length ? (
              <h1>No client data</h1>
            ) : (
              <Clients data={searchedDataClient} error={error} />
            )}
          </div>
          <div hidden={active !== 0}>
            {error2?.length ? (
              <h1>No partner data</h1>
            ) : (
              <PartnersPage data={searchedDataPartner} error={error} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
