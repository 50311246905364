import React, { useEffect } from 'react';
import SideBar from '../../../Components/SideBar/SideBar';
import Header from '../../../Components/Header/Header';
import './Role.scss';
import { useDispatch } from 'react-redux';
import { getRoles, deleteRole } from '../../../RoleApi/roleSlice';
import TableHeader from '../Users/Table/TableHeader/TableHeader';
import RoleInfo from './RoleInfo/RoleInfo';
import { useSelector } from 'react-redux';
const Role = () => {
  const token = useSelector((state) => state.auth.token);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.role.roles);
  const data = useSelector((state) => state.role.roles);

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  const roleInfo = [
    { id: 0, name: 'Name', roleName: 'Supervisor' },
    { id: 1, name: 'Name', roleName: 'Supervisor' },
    { id: 2, name: 'Name', roleName: 'Supervisor' },
  ];
  return (
    <div className="role-container">
      <SideBar />
      <div>
        <Header title="Role & Permissions" />
        <div className="mid">
          <TableHeader link={'/rolePermission'} />
          {/* {loading ? (
            <h1>Loading</h1>
          ) : ( */}
          <RoleInfo
            roleInfo={data}
            deleteRole={deleteRole}
            dispatch={dispatch}
            token={token}
          />
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

export default Role;
