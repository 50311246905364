import React, { useState } from "react";
import ProgressFullBar from "../ProgressFullBar/ProgressFullBar";
import "./ProductOne.scss";
import { useDispatch } from "react-redux";
import { Countries } from "../../../../../api/CountryApi";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { useAlert } from "react-alert";
import {
  getAllCategories,
  getAllCategoriesWithChildren,
} from "../../../../../api/CategoryApi/CategorySlice.js";
import { getallLang } from "../../../../../api/LanguageApi/Lang";
const AddProductOne = ({
  onNext,
  name,
  setName,
  seo,
  setSeo,
  category,
  setCategory,
  setDesc,
  desc,
  productLocation,
  setProductLocation,
  modelNum,
  setModelNum,
  isActive,
  setIsActive,
  featured,
  setFeatured,
  selectedOption,
  setSelectedOption,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCategoriesWithChildren());
  }, [dispatch]);
  const data = useSelector((state) => state?.category?.categoryWithChild?.data);

  // console.log(d);
  const handleChange = (event) => {
    const selectedCategoryId = event.target.value;
    setCategory([selectedCategoryId]);
  };
  const handleChange2 = (event) => {
    const selectedCountry = event.target.value;
    setProductLocation(selectedCountry);
  };
  const handleActiveChange = () => {
    setIsActive(1);
    setFeatured(0);
  };
  const handleFeaturedChange = () => {
    setFeatured(1);
    setIsActive(0);
  };
  const langs = useSelector((state) => state?.Lang?.lang?.langs);
  const options = langs?.map((language) => ({
    value: language.id,
    label: language.name,
  }));

  useEffect(() => {
    dispatch(getallLang());
  }, [dispatch]);
  return (
    <div>
      <div>
        <ProgressFullBar showImage={false} showSpec={false} completed={50} />
      </div>
      <div className="AddProductForm">
        <div className="FormName">
          <span>Product Name</span>
          <input
            type="text"
            placeholder="Enter Product Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="selectForm">
          <div className="selectForm">
            <label htmlFor="category">Category</label>
            <select id="category" onChange={handleChange}>
              <option>Select Your Category</option>
              {data?.map((item, i) =>
                item?.children?.map((item) =>
                  item?.children?.map((item) => (
                    <option key={item?.id} value={item?.id}>
                      {item.name}
                    </option>
                  ))
                )
              )}
            </select>
          </div>
        </div>
        <div className="selectForm">
          <div className="selectForm">
            <label htmlFor="Country">Country</label>
            <select
              id="Country"
              value={productLocation}
              multiple={false}
              onChange={handleChange2}
            >
              <option>Select Country</option>
              {Countries?.map((item, i) => (
                <option key={i} value={item?.code}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="FormName">
          <span>Description</span>
          <input
            type="text"
            placeholder="Enter Model description"
            onChange={(e) => setDesc(e.target.value)}
          />
        </div>
        <div className="FormName">
          <span>Enter Model_no</span>
          <input
            type="text"
            placeholder="Enter Model Number"
            onChange={(e) => setModelNum(e.target.value)}
          />
        </div>
        {/* <div className="ProductCheck">
          <div>
            <input
              type="radio"
              name="status"
              id="active"
              checked={isActive}
              onChange={handleActiveChange}
            />
            <label htmlFor="active">Is_Active</label>
          </div>
          <div>
            <input
              type="radio"
              name="status"
              id="featured"
              checked={featured}
              onChange={handleFeaturedChange}
            />
            <label htmlFor="featured">Feature</label>
          </div>
        </div> */}
        <div className="FormName">
          <span>Product SEO</span>
          <input
            type="text"
            placeholder="Enter Product SEO"
            value={seo}
            onChange={(e) => setSeo(e.target.value)}
          />
        </div>
        <div style={{ width: "10vw" }}>
          <p>Language</p>
          <Select
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            options={options}
          />
        </div>

        <div className="AddProductBtn">
          <button onClick={onNext}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default AddProductOne;
