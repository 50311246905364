import React, { useEffect, useState } from "react";
import Header from "../../../../Components/Header/Header";
import SideBar from "../../../../Components/SideBar/SideBar";
import "./addLocation.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateLocationApi,
  getLocation,
} from "../../../../api/LocationApi/Location";
import { useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import { Countries } from "../../../../api/CountryApi";
import Select from "react-select";

const UpdateLocation = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [email, setEmail] = useState("");
  const [fax, setFax] = useState("");
  const [latitude, setLatitude] = useState("");
  const [langitude, setLangitude] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const alert = useAlert();
  const [productLocation, setProductLocation] = useState("");
  const data = useSelector(
    (state) => state?.location?.singleLocation?.location
  );
  useEffect(() => {
    dispatch(getLocation(id));
    if (data) {
      setAddress(data?.address);
      setPhone(data?.phone);
      setWebsite(data?.website_url);
      setEmail(data?.email);
      setFax(data?.fax);
      setLatitude(data?.lat);
      setLangitude(data?.lang);
      setLangitude(data?.lang);
      setSelectedOption({
        label: data?.language?.name,
        value: data?.language?.id,
      });
      setProductLocation({ label: data?.location });
    }
  }, [dispatch]);
  const langs = useSelector((state) => state?.Lang?.lang?.langs);
  const options = langs?.map((language) => ({
    value: language.id,
    label: language.name,
  }));
  const optionsCoun = Countries?.map((item) => ({
    value: item.code,
    label: item.name,
  }));
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      address: address,
      phone: phone,
      website_url: website,
      email: email,
      fax: fax,
      lat: latitude,
      lang: langitude,
      language_id: selectedOption?.value,
      location: productLocation?.label,
    };
    dispatch(UpdateLocationApi({ id: id, newData: data }))
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        alert.show("Sucessful Updated");
        console.log(originalPromiseResult);
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        alert.show(rejectedValueOrSerializedError?.response?.data?.message);

        // alert(rejectedValueOrSerializedError.response.data.message);
        console.log(rejectedValueOrSerializedError);
      });
    console.log(data);
  };
  return (
    <div className="locationContainer">
      <SideBar />
      <div className="locationWrapper">
        <Header title={"Location"} />
        <form onSubmit={handleSubmit} className="locationBody">
          <div style={{ width: "22vw" }}>
            <p>Language</p>
            <Select
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={options}
              value={selectedOption}
            />
          </div>
          <div style={{ width: "22vw" }}>
            <p>Country</p>
            <Select
              defaultValue={productLocation}
              onChange={setProductLocation}
              options={optionsCoun}
              value={productLocation}
            />
          </div>
          <div className="locationInput">
            <span>Address</span>
            <input
              type="text"
              value={address}
              placeholder="Address"
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className="locationInput">
            <span>Phone</span>
            <input
              type="text"
              value={phone}
              placeholder="Phone"
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div className="locationInput">
            <span>Website_URL</span>
            <input
              type="text"
              placeholder="Website_URL"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </div>
          <div className="locationInput">
            <span>Email</span>
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="locationInputContainer">
            <div className="inputSmall">
              <span>Fax</span>
              <input
                type="text"
                placeholder="Fax"
                value={fax}
                onChange={(e) => setFax(e.target.value)}
              />
            </div>
            <div className="inputSmall">
              <span>Latitute</span>
              <input
                type="text"
                placeholder="Latitute"
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)}
              />
            </div>
            <div className="inputSmall">
              <span>Langitute</span>
              <input
                type="text"
                placeholder="Address"
                value={langitude}
                onChange={(e) => setLangitude(e.target.value)}
              />
            </div>
          </div>
          <button>Save</button>
        </form>
      </div>
    </div>
  );
};

export default UpdateLocation;
