import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../Consts";
export const getAllEmails = createAsyncThunk(
  "emailUs/getEmails",
  async (_, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/EmailUs`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data);
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error.message);
    }
  }
);
export const deleteEmail = createAsyncThunk(
  "email/deleteEmail",
  async (id, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(`${BASE_URL}/EmailUs/delete/${id}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      // console.log(response);
      return id; // Return the ID of the deleted news item
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

const EmailUsSlice = createSlice({
  name: "news",
  initialState: { emails: [], loading: false, error: null },
  extraReducers: {
    [getAllEmails.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getAllEmails.fulfilled]: (state, action) => {
      state.emails = action.payload;
      state.loading = false;
    },
    [getAllEmails.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    [deleteEmail.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [deleteEmail.fulfilled]: (state, action) => {
      // Remove the deleted news item from the news array
      state.emails = state.emails.emails.data.filter(
        (emailItem) => emailItem.id !== action.payload
      );
      state.loading = false;
    },
    [deleteEmail.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});
export default EmailUsSlice.reducer;
