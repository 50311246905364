import React, { useEffect, useState } from "react";
import "./CategoryAdd.scss";
import SideBar from "../../../../Components/SideBar/SideBar";
import Header from "../../../../Components/Header/Header";
import { useDispatch } from "react-redux";
import { AddCategory } from "../../../../api/CategoryApi/CategorySlice";
import { useSelector } from "react-redux";
import Select from "react-select";
import { useAlert } from "react-alert";
import {
  getAllCategories,
  DeleteCategory,
} from "../../../../api/CategoryApi/CategorySlice.js";
import { getallLang } from "../../../../api/LanguageApi/Lang";
const CategoryAdd = () => {
  const [name, setName] = useState("");
  const [seo, setSeo] = useState("");
  const [img, setimg] = useState();
  const [cat, setCat] = useState("");
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const alert = useAlert();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllCategories());
    dispatch(getallLang());
  }, [dispatch]);
  const data = useSelector((state) => state?.category?.category?.data);
  const loading = useSelector((state) => state?.category?.loading);
  const langs = useSelector((state) => state?.Lang?.lang?.langs);
  //   console.log(langs, "----");
  const options = langs?.map((language) => ({
    value: language.id,
    label: language.name,
  }));
  // console.log(loading);
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: name,
      seo: seo,
      image: img,
      parent_id: cat,
      language_id: selectedOption?.value,
    };
    dispatch(AddCategory(data))
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        alert.show("Sucessful Added");
        setName("");
        setimg("");
        setCat("");
        setSeo("");
        setErr("");
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        alert.show(rejectedValueOrSerializedError?.response?.data?.message);

        setErr(rejectedValueOrSerializedError.response.data.errors);
        setSuccess("");
      });
  };
  // console.log(err);

  return (
    <div className="categoryAdd-container">
      <SideBar />
      <div className="categoryAdd-right">
        <Header title={"Category"} />
        <form className="categoryAdd-Bottom" onSubmit={handleSubmit}>
          <div>
            <p>Add Category</p>
            <div className="inputCategory">
              <span>Name</span>
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {err.name && <p className="error">{err.name[0]}</p>}
            </div>
            <div className="inputCategory">
              <span>SEO</span>
              <input
                type="text"
                placeholder="SEO"
                value={seo}
                onChange={(e) => setSeo(e.target.value)}
              />
              {err.seo && <p className="error">{err.seo[0]}</p>}
            </div>
            <div className="file">
              <input
                type="file"
                placeholder="Choose an image"
                alt="Category-alt"
                // value={img}
                onChange={(e) => setimg(e.target.files[0])}
              />
              {err.image && <p className="error">{err.image[0]}</p>}
            </div>
          </div>

          <div className="categorySelect">
            <select onChange={(e) => setCat(e.target.value)}>
              <option value={""}>Select Parent Category</option>
              {data?.map((item, i) => (
                <option value={item.id} key={i}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div style={{ width: "22vw" }}>
            <Select
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={options}
            />
          </div>
          <div className="categoryBtn">
            <button>Save</button>
          </div>
          {/* {loading ? <span>Loading</span> : ''} */}
          {success && <p className="success">{success}</p>}
        </form>
      </div>
    </div>
  );
};

export default CategoryAdd;
