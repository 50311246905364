import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DeleteProductSpe from "../DelteProduct/DeleteProductSpe";
import { MdOutlineKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { useState } from "react";
import ProductSpeUpdate from "../ProductSpeUpdate/ProductSpeUpdate";
function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [item, setItem] = useState(null);
  const [itemUpdate, setItemUpdate] = useState(null);
  const handleDelete = (id) => {
    setShowWarning(!showWarning);
    setItem(id);
  };
  const handleUpdate = (item) => {
    setItemUpdate(item);
    setShowUpdate(!showUpdate);
  };
  // console.log(item);
  return (
    <>
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <MdKeyboardArrowDown /> : <MdOutlineKeyboardArrowUp />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.id}
          </TableCell>
          <TableCell>{row.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Id</TableCell>
                      <TableCell>Key</TableCell>
                      <TableCell>Value</TableCell>
                      <TableCell>Update</TableCell>
                      <TableCell>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.specifications?.map((historyRow) => (
                      <TableRow key={historyRow.id}>
                        <TableCell component="th" scope="row">
                          {historyRow.id}
                        </TableCell>
                        <TableCell>{historyRow.key}</TableCell>
                        <TableCell>{historyRow.value}</TableCell>
                        <TableCell>
                          <button
                            onClick={() => handleUpdate(historyRow)}
                            style={{
                              width: "108px",
                              height: "37px",
                              background: "#4FCF3B",
                              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                              borderRadius: "6px",
                              outline: "none",
                              border: "none",
                              color: "white",
                              cursor: "pointer",
                              fontWeight: "400",
                              fontSize: "16px",
                            }}
                          >
                            Update
                          </button>
                        </TableCell>
                        <TableCell>
                          <button
                            onClick={() => handleDelete(historyRow.id)}
                            style={{
                              width: "108px",
                              height: "37px",
                              background: "#E30000",
                              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                              borderRadius: "6px",
                              outline: "none",
                              border: "none",
                              color: "white",
                              fontWeight: "400",
                              cursor: "pointer",
                              fontSize: "16px",
                            }}
                          >
                            Delete
                          </button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
      <div>
        <DeleteProductSpe
          showWarning={showWarning}
          setShowWarning={setShowWarning}
          id={item}
        />
        <div hidden={showUpdate !== true}>
          <ProductSpeUpdate
            item={itemUpdate}
            setShowUpdate={setShowUpdate}
            showUpdate={showUpdate}
          />
        </div>
      </div>
    </>
  );
}

export default function CollapsibleTable({ data }) {
  return (
    <>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 &&
              data?.map((row) => <Row key={row.id} row={row} />)}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
