import React, { useEffect, useState } from "react";
import SideBar from "../../../Components/SideBar/SideBar";
import Header from "../../../Components/Header/Header";
import "./UpdateAbout/updateAbout.scss";
import { useDispatch, useSelector } from "react-redux";
import { addAbout } from "../../../api/About-usApi/AboutUsApiSlice";
import { getallLang } from "../../../api/LanguageApi/Lang";
import Select from "react-select";
import { useAlert } from "react-alert";

const AddAbout = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const alert = useAlert();

  const dispatch = useDispatch();
  const [desc, setDesc] = React.useState("");
  const [vision, setVision] = React.useState("");
  const [visionImg, setVisionImg] = React.useState("");
  const [mission, setmission] = React.useState("");
  const [missionImg, setMissionImg] = React.useState("");
  const [goal, setGoal] = React.useState("");
  const [goalImg, setGoalImg] = React.useState("");
  useEffect(() => {
    dispatch(getallLang());
  }, [dispatch]);
  const langs = useSelector((state) => state?.Lang?.lang?.langs);
  //   console.log(langs, "----");
  const options = langs?.map((language) => ({
    value: language.id,
    label: language.name,
  }));
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      business_description: desc,
      vision: vision,
      mission: mission,
      goal: goal,
      vision_image: visionImg,
      mission_image: missionImg,
      goal_image: goalImg,
      language_id: selectedOption.value,
    };
    console.log(data);
    dispatch(addAbout(data))
      .unwrap()
      .then((originalPromiseResult) => {
        alert.show("Sucessful Added");
        console.log(originalPromiseResult);
        dispatch(getallLang());
      })
      .catch((rejectedValueOrSerializedError) => {
        alert.show(rejectedValueOrSerializedError?.response?.data?.message);

        console.log(rejectedValueOrSerializedError);
      });
  };
  return (
    <div className="updateAbout">
      <SideBar />
      <div className="wrapper">
        <Header title="About" />
        <form
          className="AboutWrapper"
          encType="multipart/form-data"
          onSubmit={handleSubmit}
        >
          <div style={{ width: "20vw" }}>
            <Select
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={options}
            />
          </div>
          <div className="AboutInput">
            <span>Description</span>
            <textarea
              type="text"
              name="desc"
              value={desc}
              id=""
              onChange={(e) => setDesc(e.target.value)}
              placeholder="Type a Description"
            />
          </div>

          <div className="AboutInput">
            <span>Vision</span>
            <div className="inputAbout2">
              <input
                type="text"
                name="Vision"
                id=""
                value={vision}
                onChange={(e) => setVision(e.target.value)}
                placeholder="Type a Vision"
              />
              <input
                type="file"
                encType="multipart/form-data"
                accept="image/*"
                name={"vision_image"}
                // value={visionImg}
                onChange={(e) => {
                  setVisionImg(e.target.files[0]);
                }}
              />
            </div>
          </div>
          <div className="AboutInput">
            <span>Mission</span>
            <div className="inputAbout2">
              <input
                type="text"
                name="Vision"
                id=""
                value={mission}
                placeholder="Type a Mission"
                onChange={(e) => setmission(e.target.value)}
              />
              <input
                type="file"
                name="mission_image"
                encType="multipart/form-data"
                accept="image/*"
                onChange={(e) => setMissionImg(e.target.files[0])}
              />
            </div>
          </div>
          <div className="AboutInput">
            <span>Goal</span>
            <div className="inputAbout2">
              <input
                type="text"
                name="Goal"
                id=""
                value={goal}
                placeholder="Type a Goal"
                onChange={(e) => setGoal(e.target.value)}
              />
              <input
                type="file"
                name={"goal_image"}
                encType="multipart/form-data"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  setGoalImg(file);
                }}
              />
            </div>
          </div>
          <button>Save</button>
        </form>
      </div>
    </div>
  );
};

export default AddAbout;
