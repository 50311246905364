import React, { useEffect, useState } from "react";
import "./ProductSpeUpdate.scss";
import { AiOutlineCloseCircle } from "react-icons/ai";
import {
  getAllProductInformations,
  updateProductSpe,
} from "../../../../api/productInformation/productInformationSlice";
import { useDispatch, useSelector } from "react-redux";

const ProductSpeUpdate = ({ showUpdate, setShowUpdate, item }) => {
  const [productInfo, setProductInfo] = useState(null);
  const [value, setValue] = useState(null);
  const [key, setKey] = useState(null);
  const handleChange = (e) => {
    setProductInfo(e.target.value);
  };
  console.log(item);

  const dispatch = useDispatch();

  useEffect(() => {
    if (item) {
      setValue(item.value);
      setKey(item.key);
    }
    dispatch(getAllProductInformations())
      .unwrap()
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        // setError(err.response.status);
      });
  }, [dispatch]);

  const productsInfo = useSelector(
    (state) => state?.productInformation?.productInofrmations?.resulte || []
  );
  //   console.log(item?.id);
  useEffect(() => {
    if (item) {
      setValue(item.value);
      setKey(item.key);
    }
    if (item?.information_id) {
      setProductInfo(item.information_id);
    }
  }, [item]);
  const handleUpdate = () => {
    const data = {
      product_id: item.product_id,
      key,
      value,
      information_id: productInfo,
    };
    console.log(data);
    dispatch(updateProductSpe({ id: item.id, newData: data }));
  };
  return (
    <div className={`speWrapper`}>
      <AiOutlineCloseCircle
        className="closeBtn"
        onClick={() => setShowUpdate(!showUpdate)}
      />
      <select
        id="Country"
        value={productInfo}
        className="speSelect"
        onChange={handleChange}
      >
        <option>Select Product</option>
        {productsInfo.length > 0 &&
          productsInfo.map((item, i) => (
            <option key={i} value={item?.id}>
              {item.name}
            </option>
          ))}
      </select>

      <div className="speInput">
        <span>key</span>
        <input
          type="text"
          //   placeholder={item?.key}
          value={key}
          onChange={(e) => setKey(e.target.value)}
        />
      </div>
      <div className="speInput">
        <span>value</span>
        <input
          type="text"
          value={value}
          // placeholder={item?.value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
      <button className="speButton" onClick={() => handleUpdate()}>
        Update
      </button>
    </div>
  );
};

export default ProductSpeUpdate;
