import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: { user_name: null, token: null },
  reducers: {
    setCredentials: (state, action) => {
      const { user_name, access_token } = action.payload;
      state.user_name = user_name;
      state.token = access_token;
      localStorage.setItem("token", state.token);
    },
    logOut: (state, action) => {
      state.user_name = null;
      state.token = null;
    },
  },
  extraReducers: {},
});

export const { setCredentials, logOut } = authSlice.actions;
export default authSlice.reducer;
export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
