import React, { useEffect } from 'react';
import SideBar from '../../../Components/SideBar/SideBar';
import Header from '../../../Components/Header/Header';
import './email.scss';
import { AiOutlineSearch } from 'react-icons/ai';
import EmailTable from './EmailTable';
import { useDispatch, useSelector } from 'react-redux';
import { getAllEmails } from '../../../api/EmailWcmApi/EmailApi.js';
const EmailWCM = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.emails.emails.data);
  useEffect(() => {
    dispatch(getAllEmails());
  }, [dispatch]);
  // console.log(data);
  return (
    <div className="emailContainer">
      <SideBar />
      <div className="emailWrapper">
        <Header title="Email " />
        <div className="emailBodyContainer">
          <div className="inputContainer">
            <input type="text" placeholder="Search for Anything..." />
            <AiOutlineSearch />
          </div>
          <EmailTable data={data} />
        </div>
      </div>
    </div>
  );
};

export default EmailWCM;
