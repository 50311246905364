import React, { useEffect } from "react";
import "./DeleteProdcut.scss";
import { useDispatch } from "react-redux";
import { DeleteSpec } from "../../../../api/productInformation/productInformationSlice";
import { useNavigate } from "react-router-dom";
const DeleteProductSpe = ({ showWarning, setShowWarning, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleDelete = () => {
    dispatch(DeleteSpec(id));
    setShowWarning(!showWarning);
  };

  return (
    <div>
      <div className={`${showWarning ? "warning" : "hidden"} `}>
        <div className="war-header">
          <p>Confirm Delete</p>
        </div>
        <div className="bottom">
          <div>
            <p>Are You Sure You Want Delete this?</p>
          </div>
          <div className="btns">
            <button onClick={handleDelete}>Yes</button>
            <button onClick={() => setShowWarning(!showWarning)}>No</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteProductSpe;
