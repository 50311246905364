import React from 'react';
import '../News/DeleteNew/DeleteNew.scss';
import { useDispatch } from 'react-redux';
import {
  deletePartner,
  getPartner,
} from '../../../api/PartnerApi/PartnerSlice.js';
import { useNavigate } from 'react-router-dom';
import { getCLient } from '../../../api/ClientApi/ClientApi';

const DeletePartner = ({ showWarning, setShowWarning, id }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(deletePartner(id))
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        console.log(originalPromiseResult);
      })
      .catch((rejectedValueOrSerializedError) => {
        dispatch(getCLient());
        // handle error here
      });
    // navigate('/wcmAbout');
    setShowWarning(!showWarning);
  };
  return (
    <div className={`${showWarning ? 'warning' : 'hidden'} `}>
      <div className="war-header">
        <p>Confirm Delete</p>
      </div>
      <div className="bottom">
        <div>
          <p>Are You Sure You Want Delete this?</p>
        </div>
        <div className="btns">
          <button onClick={handleDelete}>Yes</button>
          <button onClick={() => setShowWarning(!showWarning)}>No</button>
        </div>
      </div>
    </div>
  );
};

export default DeletePartner;
