import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../Consts";
export const getAllNews = createAsyncThunk(
  "news/getNews",
  async (_, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/Blogs`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data);
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);
export const getNewById = createAsyncThunk(
  "news/getNews",
  async (id, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/Blogs/show/${id}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data);
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const insertNew = createAsyncThunk(
  "news/updateNew",
  async (newData, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(`${BASE_URL}/Blogs/store`, newData, {
        headers: {
          "Content-Type": "application/json,multipart/form-data",
          Accept: "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const UpdateNewApi = createAsyncThunk(
  "news/updateNew",
  async ({ id, newData }, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${BASE_URL}/Blogs/update/${id}`,
        newData,
        {
          headers: {
            "Content-Type": "application/json,multipart/form-data",
            Accept: "application/json",

            authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response);
      return response.data; // Return the response data
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);
export const deleteNew = createAsyncThunk(
  "news/deleteNew",
  async (id, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(`${BASE_URL}/Blogs/delete/${id}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      // console.log(response);
      return id; // Return the ID of the deleted news item
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);

const newsSlice = createSlice({
  name: "news",
  initialState: { news: [], loading: false, error: null, success: true },
  extraReducers: {
    [getAllNews.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getAllNews.fulfilled]: (state, action) => {
      state.news = action.payload;
      state.loading = false;
    },
    [getAllNews.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getNewById.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getNewById.fulfilled]: (state, action) => {
      state.new = action.payload;
      state.loading = false;
    },
    [getNewById.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [insertNew.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    [insertNew.fulfilled]: (state, action) => {
      state.news = action.payload;
      state.loading = false;
      state.success = true;
    },
    [insertNew.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.success = false;
    },
    [UpdateNewApi.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    // [UpdateNewApi.fulfilled]: (state, action) => {
    //   // Find the index of the updated news item in the news array
    //   const updatedNewsIndex = state.news.news.data.findIndex(
    //     (newsItem) => newsItem.id === action.payload.id
    //   );
    //   console.log(updatedNewsIndex);
    //   // Replace the old news item with the updated one
    //   state.news[updatedNewsIndex] = action.payload;
    //   state.loading = false;
    // },
    [UpdateNewApi.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [deleteNew.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [deleteNew.fulfilled]: (state, action) => {
      // Remove the deleted news item from the news array
      state.news = state.news?.new?.data.filter(
        (newsItem) => newsItem.id !== action.payload
      );
      state.loading = false;
    },
    [deleteNew.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});
export default newsSlice.reducer;
