import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getRoles = createAsyncThunk(
  'role/getRols',
  async (_, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const { token } = getState().auth; // Get the access token from the auth state

    try {
      const res = await fetch(
        'http://192.168.1.174:8090/Nardi/public/api/roles',
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      return data;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const addRole = createAsyncThunk(
  'role/addRole',
  async (data, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {
      const res = await fetch('https://jsonplaceholder.typicode.com/users', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });
      const dataAddRole = res.json();
      return dataAddRole;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
export const deleteRole = createAsyncThunk(
  'role/deleteRole',
  async (id, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {
      await fetch(`https://jsonplaceholder.typicode.com/users/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
      });
      // const dataAddRole = res.json();
      console.log(id);
      return id;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
export const updateRole = createAsyncThunk(
  'role/updateRole',
  async (role, thunkApi) => {
    const { rejectWithValue } = thunkApi;
    try {
      const res = await fetch(
        `https://jsonplaceholder.typicode.com/users/${role.id}`,
        {
          method: 'PUT',
          body: JSON.stringify(role),
          headers: {
            'Content-Type': 'application/json; charset=UTF-8',
          },
        }
      );
      const updatedRole = await res.json();
      return updatedRole;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);
const roleSlice = createSlice({
  name: 'role',
  initialState: { roles: [], loading: false, error: null },
  extraReducers: {
    //Get Roles
    [getRoles.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getRoles.fulfilled]: (state, action) => {
      state.roles = action.payload;
      state.loading = false;
    },
    [getRoles.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    //Add Role
    [addRole.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [addRole.fulfilled]: (state, action) => {
      state.roles.push(action.payload);
      state.loading = false;
    },
    [addRole.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    // Delete Role
    [deleteRole.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [deleteRole.fulfilled]: (state, action) => {
      state.roles = state.roles.filter((role) => role.id !== action.payload);
      state.loading = false;
    },
    [deleteRole.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    // Update Role
    [updateRole.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [updateRole.fulfilled]: (state, action) => {
      const updatedRole = action.payload;
      const index = state.roles.findIndex((role) => role.id === updatedRole.id);
      if (index !== -1) {
        state.roles[index] = updatedRole;
      }
      state.loading = false;
    },
    [updateRole.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default roleSlice.reducer;
