import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import DeleteContact from './deleteContact';

export default function BasicTable({ data }) {
  const [showWarning, setShowWarning] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const handleDelete = (id) => {
    setSelectedRow(id);
    setShowWarning(!showWarning);
  };
  // console.log(data);
  return (
    <div>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, i) => (
              <TableRow
                key={i}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{++i}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>{row.subject}</TableCell>
                <TableCell>{row.received}</TableCell>
                <TableCell>
                  {' '}
                  <button
                    className="delete"
                    onClick={(e) => handleDelete(row.id)}
                  >
                    Delete
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showWarning && (
        <DeleteContact
          id={selectedRow}
          setShowWarning={setShowWarning}
          showWarning={showWarning}
        />
      )}
    </div>
  );
}
