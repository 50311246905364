import React, { useEffect, useRef, useState } from 'react';
import './roles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addRole, updateRole } from '../../../../../RoleApi/roleSlice';
import {
  getPermissions,
  AddPermission,
} from '../../../../../PremissionApi/Permission';
const Roles = () => {
  const name = useRef(null);
  const prem = useRef(null);
  const dispatch = useDispatch();
  const permission = useSelector((state) => state.permission.permissions);
  const loading = useSelector((state) => state.permission.permissions);
  const [selectedPermissionIds, setSelectedPermissionIds] = useState([]);

  useEffect(() => {
    dispatch(getPermissions());
  }, [dispatch]);
  const handlePermissionChange = (e) => {
    const id = parseInt(e.target.id, 10);
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedPermissionIds([...selectedPermissionIds, id]);
    } else {
      setSelectedPermissionIds(
        selectedPermissionIds.filter((selectedId) => selectedId !== id)
      );
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: name.current.value,
      ids: selectedPermissionIds,
    };
    dispatch(AddPermission(data));
    name.current.value = '';
    prem.current.value = '';
    // console.log(data);
  };
  return (
    <form className="container" onSubmit={handleSubmit}>
      <div className="input-container">
        <span>Name:</span>
        <input type="text" placeholder="Name" ref={name} />
      </div>
      <span className="spanPer">Permissions</span>

      <div className="per">
        {permission.data?.map((per, i) => (
          <div className="check" key={i}>
            <input
              type="checkbox"
              name="per"
              id={per.id}
              value={per.id}
              ref={prem}
              onChange={handlePermissionChange}
            />
            <span key={i}>{per.description}</span>
          </div>
        ))}
      </div>

      <div>
        <button>Save</button>
      </div>
    </form>
  );
};

export default Roles;
//
