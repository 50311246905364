import React, { useEffect } from "react";
import SideBar from "../../../Components/SideBar/SideBar";
import Header from "../../../Components/Header/Header";
import "./about.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAbout,
  getAboutUs,
} from "../../../api/About-usApi/AboutUsApiSlice";
import { BASE_URL_IMAGE } from "../../../Consts";
import { useAlert } from "react-alert";
const About = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAboutUs());
  }, [dispatch]);
  const data = useSelector((state) => state?.about?.about?.data || []);
  // console.log(data);
  const handleDelete = (item) => {
    dispatch(deleteAbout(item?.id))
      .unwrap()
      .then((originalPromiseResult) => {
        alert.show("Sucessful Deleted");
        console.log(originalPromiseResult);
        dispatch(getAboutUs());
      })
      .catch((rejectedValueOrSerializedError) => {
        alert.show(rejectedValueOrSerializedError?.response?.data?.message);

        console.log(rejectedValueOrSerializedError);
      });
  };
  return (
    <div className="aboutContainer">
      <SideBar />
      <div className="wrapper">
        <Header title={"About US"} />
        <div className="aboutContainer">
          <div className="aboutBody">
            <h1>All</h1>
            <div className="aboutLeftContainer">
              <Link to="/aboutUsAdd">
                <button>Add</button>
              </Link>
            </div>
          </div>
          <div className="head">
            <p>Bussiness Describtion</p>
            <p>Vision </p>
            <p>Mision</p>
            <p>Goal</p>
            <p>Lang</p>
          </div>
          {data?.length > 0 &&
            data?.map((item, index) => (
              <div className="body" key={index}>
                <p>{item?.business_description.substring(0, 100)}</p>
                <div className="aboutItem">
                  <img src={`${BASE_URL_IMAGE}/${item?.vision_image}`} alt="" />
                  <p>{item?.vision.substring(0, 100)}</p>
                </div>
                <div className="aboutItem">
                  <img
                    src={`${BASE_URL_IMAGE}/${item?.mission_image}`}
                    alt=""
                  />
                  <p>{item?.mission.substring(0, 100)}</p>
                </div>
                <div className="aboutItem">
                  <img src={`${BASE_URL_IMAGE}/${item?.goal_image}`} alt="" />
                  <p>{item?.goal.substring(0, 100)}</p>
                </div>
                <div className="aboutItem">
                  <p>{item?.lang?.name}</p>
                </div>
                <div className="aboutButtons">
                  <button
                    className="update"
                    onClick={() => navigate(`/aboutUsUpdate/${item?.id}`)}
                  >
                    Update
                  </button>
                  <button className="delete" onClick={() => handleDelete(item)}>
                    Delete
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default About;
