import React, { useState } from "react";
import Lap from "../../../../images/laptop2";
import "./ProductTable.scss";
import Warning from "../../../../Components/warning/Warning";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom";
import DeleteProduct from "../DelteProduct/DeleteProduct";
import { BASE_URL_IMAGE_Product } from "../../../../Consts";
const ProductTable = ({ data }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const handleDelete = (id) => {
    setSelectedRow(id);
    setShowWarning(!showWarning);
  };
  return (
    <div className="tableContainer">
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Product Category</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Describtion</TableCell>
              <TableCell>Lan</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>SEO</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, i) => (
              <TableRow
                key={i}
                sx={{
                  marginBottom: "1rem",
                  gap: "0rem",
                }}
              >
                <TableCell component="th" scope="row" sx={{ gap: "0rem" }}>
                  {++i}
                </TableCell>
                <TableCell>{row?.name}</TableCell>
                <TableCell>{row?.categories[0]?.name}</TableCell>
                <TableCell>
                  <img
                    key={row?.id}
                    style={{ width: "96px", height: "90px" }}
                    src={
                      `${BASE_URL_IMAGE_Product}/${row?.images[0]?.image}` ||
                      "https://via.placeholder.com/150"
                    }
                    alt="images"
                  />{" "}
                </TableCell>
                <TableCell>{row?.description}</TableCell>
                <TableCell>{row?.location}</TableCell>
                <TableCell>{row?.lang?.name}</TableCell>
                <TableCell>{row?.seo}</TableCell>
                {/* {console.log(row, "[=====")} */}
                <TableCell>
                  <Link to={`/updateProduct/${row.id}`}>
                    <button
                      style={{
                        width: "108px",
                        height: "37px",
                        background: "#4FCF3B",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        borderRadius: "6px",
                        outline: "none",
                        border: "none",
                        color: "white",
                        cursor: "pointer",
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                    >
                      Update
                    </button>
                  </Link>
                </TableCell>
                <TableCell>
                  {" "}
                  <button
                    onClick={(e) => handleDelete(row.id)}
                    style={{
                      width: "108px",
                      height: "37px",
                      background: "#E30000",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: "6px",
                      outline: "none",
                      border: "none",
                      color: "white",
                      fontWeight: "400",
                      cursor: "pointer",
                      fontSize: "16px",
                    }}
                  >
                    Delete
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showWarning && (
        <DeleteProduct
          id={selectedRow}
          setShowWarning={setShowWarning}
          showWarning={showWarning}
        />
      )}
    </div>
  );
};

export default ProductTable;
