import React, { useState } from "react";
import "./ProductTwo.scss";
import ProgressFullBar from "../ProgressFullBar/ProgressFullBar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { BASE_URL_IMAGE_Product } from "../../../../../Consts";
import {
  deleteProductImage,
  getProduct,
  updateProduct,
} from "../../../../../api/ProductApi/productSlice";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useAlert } from "react-alert";
const ProductTwo = ({ onPrev, setImages, images, product, data }) => {
  const dispatch = useDispatch();
  const id = useParams().id;
  const [newImages, setNewImages] = useState([]);
  const [displayImages, setDisplayImages] = useState(product?.images);
  // console.log(displayImages);
  // useEffect(() => {
  //   setImages(displayImages);
  // }, [images]);
  const handleDelete = (image, index) => {
    if (!image.id) {
      setDisplayImages(newImages?.filter((_, i) => i !== index));
      setImages(displayImages);
    } else {
      dispatch(deleteProductImage(image?.id))
        .unwrap()
        .then((originalPromiseResult) => {
          alert.show("Sucessful Deleted");
          dispatch(getProduct(id));
          console.log(originalPromiseResult);
        })
        .catch((rejectedValueOrSerializedError) => {
          alert.show(rejectedValueOrSerializedError?.response?.data?.message);
          console.log(rejectedValueOrSerializedError);
        });
    }
  };
  const handleAdd = () => {
    setDisplayImages([...displayImages, ...images]);
    // setImages([...newImages]);
    setNewImages([...newImages, ...images]);

    // console.log(displayImages, "/////");
    // console.log(images, "sssaaaa");
    // console.log(newImages, "sss");
  };
  const alert = useAlert();
  const handleUpdate = () => {
    console.log(data, "update");
    dispatch(updateProduct({ id: id, productData: data }))
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        alert.show("Sucessful Updated");
        console.log(originalPromiseResult);
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        alert.show(rejectedValueOrSerializedError?.response?.data?.message);
        // alert(rejectedValueOrSerializedError.response.data.message);
      });
  };

  const rows = displayImages.map((image, index) => ({
    id: index + 1,
    image: (
      <img
        style={{ width: "50px", height: "50px" }}
        src={`${BASE_URL_IMAGE_Product}/${image.image}`}
        alt=""
      />
    ),
    action: (
      <button className="button2" onClick={() => handleDelete(image, index)}>
        Delete
      </button>
    ),
  }));

  return (
    <div>
      <div>
        <ProgressFullBar showImage={true} showSpec={false} completed={65} />
      </div>
      <div className="ProductTwoContainer">
        <div className="addinput">
          <input
            type="file"
            required
            multiple
            onChange={(e) => {
              setImages(e.target.files);
              setNewImages([]);
            }}
          />
          <button onClick={handleAdd}>Add</button>
        </div>
        <div className="">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell align="right">Image</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="right">{row.image}</TableCell>
                    <TableCell align="right">{row.action}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="backandnext">
          <button onClick={onPrev}>Back</button>
          <button onClick={() => handleUpdate()}>Update</button>
        </div>
      </div>
    </div>
  );
};

export default ProductTwo;
