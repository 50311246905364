import React, { useEffect, useState, useRef } from 'react';
import './Login.scss';
import Logo from '../../images/logo';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import userLogin from '../../auth/authSlice';
import { setCredentials } from '../../auth/authSlice';
import { useLoginMutation } from '../../auth/authApiSlice';
const Login = () => {
  const userRef = useRef();
  const errRef = useRef();
  const [user_name, setUser_name] = useState('');
  const [password, setPassword] = useState('');
  const [errmsg, setErrmsg] = useState('');
  const navigate = useNavigate();
  const [login] = useLoginMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    setErrmsg('');
  }, [user_name, password]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userDate = await login({ user_name, password }).unwrap();
      dispatch(setCredentials({ ...userDate, user_name }));
      setUser_name('');
      setPassword('');
      navigate('/wcmAbout');
    } catch (error) {
      if (!error.response) {
        setErrmsg('Invalid Username or Password');
      } else if (error.response.status === 400) {
        setErrmsg('Missing Username or Password');
      } else if (error.response.status === 401) {
        setErrmsg('Unauthorized');
      } else if (error.response.status === 403) {
        setErrmsg('Your Licensed is over');
      } else setErrmsg('Login Failed');
    }
  };

  const handleUserInput = (e) => setUser_name(e.target.value);
  const handlePasswordInput = (e) => setPassword(e.target.value);

  return (
    <div className="login-container">
      <div className="login-wrapper">
        <form onSubmit={handleSubmit}>
          <img src={Logo} alt="logo" />
          <h1>Admin Panel</h1>
          <div className="form">
            <div className="username-container">
              <span>User Name</span>
              <input
                type="text"
                placeholder="Username"
                required
                autoComplete="off"
                value={user_name}
                onChange={handleUserInput}
                // ref={userRef}
              />
            </div>
            <div className="password-container">
              <span>Password</span>
              <input
                type="password"
                placeholder="Password"
                required
                autoComplete="off"
                value={password}
                onChange={handlePasswordInput}
              />
            </div>
            <div className="check-box">
              <input type="checkbox" name="" id="" />
              <span>Remember Me</span>
            </div>
          </div>
          {errmsg && <h5 className="error">{errmsg}</h5>}
          <button type="submit">Log in</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
