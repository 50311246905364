import React from "react";
import Header from "../../../../Components/Header/Header";
import SideBar from "../../../../Components/SideBar/SideBar";
import "./addProduct.scss";
// import ProgressBar from '../../../../Components/ProgressBar/Progress';
import AddProductOne from "./ProductOne/AddProductOne";
import ProductTwo from "./ProductTwo/ProductTwo";
import ProductThree from "./ProductThree/ProductThree";
import { useState } from "react";
import { set } from "lodash";

const AddProduct = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage(currentPage - 1);
  };
  const [name, setName] = useState("");
  const [seo, setSeo] = useState("");
  const [category, setCategory] = useState([]);
  const [desc, setDesc] = useState("");
  const [productLocation, setProductLocation] = useState("");
  const [modelNum, setModelNum] = useState();
  const [isActive, setIsActive] = useState(0);
  const [featured, setFeatured] = useState(0);
  const [images, setImages] = useState([]);
  // const [spe, setSpe] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const data = {
    name: name,
    seo: seo,
    categories: category,
    description: desc,
    model_no: modelNum,
    is_active: isActive,
    featured: featured,
    image: images,
    location: productLocation,
    language_id: selectedOption?.value,
    // specifications: spe,
  };

  return (
    <div className="AddProductContainer">
      <SideBar />
      <div>
        <Header title="Product" />
        <div className="productAdd">
          {currentPage === 1 && (
            <AddProductOne
              onNext={handleNext}
              productLocation={productLocation}
              name={name}
              setName={setName}
              seo={seo}
              setSeo={setSeo}
              category={category}
              setCategory={setCategory}
              setProductLocation={setProductLocation}
              desc={desc}
              setDesc={setDesc}
              modelNum={modelNum}
              setModelNum={setModelNum}
              isActive={isActive}
              setIsActive={setIsActive}
              featured={featured}
              setFeatured={setFeatured}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          )}
          {currentPage === 2 && (
            <ProductTwo
              onNext={handleNext}
              onPrev={handlePrev}
              setImages={setImages}
              images={images}
              data={data}
            />
          )}
          {/* {currentPage === 3 && (
            <ProductThree onPrev={handlePrev} setSpe={setSpe} data={data} />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
