import React from "react";
import { useState, useEffect } from "react";
import Header from "../../../../Components/Header/Header";
import SideBar from "../../../../Components/SideBar/SideBar";
import { getProduct } from "../../../../api/ProductApi/productSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./UpdateProduct.scss";
import UpdateProductOne from "./ProductOne/UpdateProductOne";
import UpdateProductTwo from "./ProductTwo/UpdateProductTwo";
import UpdateProductThree from "./ProductThree/UpdateProductThree";

const UpdateProduct = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrev = () => {
    setCurrentPage(currentPage - 1);
  };
  useEffect(() => {
    dispatch(getProduct(id));
  }, [dispatch, id]);
  const product = useSelector((state) => state?.products?.products?.product);

  // console.log(product);
  const [name, setName] = useState();
  const [category, setCategory] = useState([]);
  const [desc, setDesc] = useState("");
  const [modelNum, setModelNum] = useState("");
  const [isActive, setIsActive] = useState("");
  const [featured, setFeatured] = useState("");
  const [images, setImages] = useState("");
  const [spe, setSpe] = useState("");
  // console.log(name);
  const [selectedOption, setSelectedOption] = useState(null);
  const [productLocation, setProductLocation] = useState("");

  useEffect(() => {
    // Check if form is changed or not
    if (product) {
      // Set the default values if no changes are made
      setName(product.name);
      setCategory(product.categories[0]?.id);
      setDesc(product.description);
      setModelNum(product.model_no);
      setIsActive(product.is_active);
      setProductLocation(product?.location);
      setFeatured(product.featured);
      //setImages(product.images);
      setSelectedOption({
        value: product?.lang?.id,
        label: product?.lang?.name,
      });
    }
  }, []);
  const data = {
    name: name,
    categories: [`${category}`],
    description: desc,
    model_no: modelNum,
    is_active: isActive,
    featured: featured,
    image: images,
    location: productLocation,
    language_id: selectedOption?.value,
  };
  // console.log(data);
  return (
    <div className="updateProductContainer">
      <SideBar />
      <div>
        <Header title={"Update Product"} />
        <div className="productWrapper">
          <div className="productAdd">
            {currentPage === 1 && (
              <UpdateProductOne
                product={product}
                onNext={handleNext}
                name={name}
                setName={setName}
                productLocation={productLocation}
                setProductLocation={setProductLocation}
                category={category}
                setCategory={setCategory}
                desc={desc}
                setDesc={setDesc}
                modelNum={modelNum}
                setModelNum={setModelNum}
                isActive={isActive}
                setIsActive={setIsActive}
                featured={featured}
                setFeatured={setFeatured}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            )}
            {currentPage === 2 && (
              <UpdateProductTwo
                id={id}
                product={product}
                onNext={handleNext}
                onPrev={handlePrev}
                setImages={setImages}
                images={images}
                data={data}
              />
            )}
            {/* {currentPage === 3 && (
              <UpdateProductThree
                onPrev={handlePrev}
                setSpe={setSpe}
                data={data}
                id={id}
                product={product}
              />
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateProduct;
