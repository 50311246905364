import React from "react";
import "./DeleteNew.scss";
import { useDispatch } from "react-redux";
import { deleteNew, getAllNews } from "../../../../api/newsApi/New.js";
import { useNavigate } from "react-router-dom";
import { redirect } from "react-router-dom";
import { useAlert } from "react-alert";

const DeleteNew = ({ showWarning, setShowWarning, id }) => {
  const navigate = useNavigate();
  const alert = useAlert();
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(deleteNew(id)).then(() => {
      dispatch(getAllNews())
        .unwrap()
        .then((originalPromiseResult) => {
          alert.show("Sucessful Deleted");
        })
        .catch((rejectedValueOrSerializedError) => {
          alert.show(rejectedValueOrSerializedError?.response?.data?.message);
        });
    });
    // navigate('/wcmAbout');
    setShowWarning(!showWarning);
  };
  return (
    <div className={`${showWarning ? "warning" : "hidden"} `}>
      <div className="war-header">
        <p>Confirm Delete</p>
      </div>
      <div className="bottom">
        <div>
          <p>Are You Sure You Want Delete this?</p>
        </div>
        <div className="btns">
          <button onClick={handleDelete}>Yes</button>
          <button onClick={() => setShowWarning(!showWarning)}>No</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteNew;
