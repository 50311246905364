import React, { useEffect, useState } from "react";
import {
  addProductInfo,
  getAllProductInformations,
} from "../../../../api/productInformation/productInformationSlice";
import "../product.scss";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../../../Components/Header/Header";
import SideBar from "../../../../Components/SideBar/SideBar";
import BasicTable from "../../../../Components/Table/Table";
import Select from "react-select";
import { useAlert } from "react-alert";
import { getAllProducts } from "../../../../api/ProductApi/productSlice";
import { getallLang } from "../../../../api/LanguageApi/Lang";
const AddProductSpe = () => {
  // const [dataFetched, setDataFetched] = useState();
  const [err, setError] = useState("");
  const [info, setInfo] = useState("");
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [spe, setSpe] = useState([]);
  const [product, setProduct] = useState([]);
  const [success, setSuccess] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const alert = useAlert();

  // console.log(spe);
  const handleChange2 = (e) => {
    setInfo(e.target.value);
  };
  const handleChange = (e) => {
    setProduct(e.target.value);
  };

  const products = useSelector(
    (state) => state?.products?.products?.data || []
  );
  const data = products?.data;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProducts())
      .unwrap()
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        setError(err.response.status);
      });
    dispatch(getAllProductInformations())
      .unwrap()
      .then((res) => {
        //  console.log(res);
      })
      .catch((err) => {
        setError(err.response.status);
      });
  }, [dispatch]);
  const productsInfo = useSelector(
    (state) => state?.productInformation?.productInofrmations?.resulte || []
  );
  const handleAddProduct = () => {
    const data = {
      product_id: product,
      information_id: info,
      specifications: spe,
      language_id: selectedOption?.value,
    };
    dispatch(addProductInfo(data))
      .unwrap()
      .then((originalPromiseResult) => {
        alert.show("Sucessful Added");
        console.log(originalPromiseResult);
        dispatch(getallLang());
      })
      .catch((rejectedValueOrSerializedError) => {
        alert.show(rejectedValueOrSerializedError?.response?.data?.message);

        console.log(rejectedValueOrSerializedError);
      });
    // console.log(data);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const newSpec = { key, value };
    setSpe((prevSpe) => [...prevSpe, newSpec]);
    setKey("");
    setValue("");
  };
  const handleDelete = (index) => {
    setSpe(spe.filter((_, i) => i !== index));
  };
  useEffect(() => {
    dispatch(getallLang());
  }, [dispatch]);
  const langs = useSelector((state) => state?.Lang?.lang?.langs);
  //   console.log(langs, "----");
  const options = langs?.map((language) => ({
    value: language.id,
    label: language.name,
  }));
  return (
    <div className="productContainer">
      <SideBar />
      <div>
        <Header title={"Product"} />
        <div className="bodyContainer">
          <form action="" onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <select
                id="product"
                value={product}
                style={{ width: "20%", height: "2rem", margin: "1rem 2rem" }}
                onChange={handleChange}
              >
                <option>Select Product</option>
                {products.length > 0 &&
                  products?.map((item, i) => (
                    <option key={i} value={item?.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
              <select
                id="info"
                value={info}
                style={{ width: "20%", height: "2rem", margin: "1rem 2rem" }}
                onChange={handleChange2}
              >
                <option>Select Info</option>
                {productsInfo?.map((item, i) => (
                  <option key={i} value={item?.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <div style={{ width: "20%" }}>
                <Select
                  defaultValue={selectedOption}
                  onChange={setSelectedOption}
                  options={options}
                />
              </div>
            </div>
            <div className="inputContainer">
              <span>Key</span>
              <div>
                <input
                  type="text"
                  placeholder="Enter Product value"
                  value={key}
                  onChange={(e) => setKey(e.target.value)}
                />
              </div>
            </div>
            <div className="inputContainer">
              <span>Value</span>
              <div>
                <input
                  type="text"
                  placeholder="Enter Product value"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
                <button type="submit" style={{ cursor: "pointer" }}>
                  Add
                </button>
              </div>
            </div>
          </form>
          <div>
            <BasicTable specs={spe} handleDelete={handleDelete} />
          </div>
          <div className="btns">
            <button onClick={() => handleAddProduct()}>Add</button>
            <p style={{ fontSize: 14, fontWeight: 500, color: "green" }}>
              {success}
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProductSpe;
