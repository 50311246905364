import React, { useEffect, useState } from "react";
import SideBar from "../../../Components/SideBar/SideBar";
import Header from "../../../Components/Header/Header";
import "./product.scss";
import TableHeader from "../../Admin/Users/Table/TableHeader/TableHeader";
import ProductTable from "./ProductTable/ProductTable";
import { useDispatch, useSelector } from "react-redux";
import { getProductSpec } from "../../../api/productInformation/productInformationSlice";
const Productspe = () => {
  // const [dataFetched, setDataFetched] = useState();
  const [err, setError] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProductSpec())
      .unwrap()
      .then((res) => {
        //  console.log(res);
      })
      .catch((err) => {
        setError(err.response.status);
      });
  }, [dispatch]);
  const products = useSelector(
    (state) => state?.productInformation?.productSpe?.data || []
  );

  // console.log(products);

  return (
    <div className="productContainer">
      <SideBar />
      <div>
        <Header title={"Product"} />
        <div className="productWrapper">
          <TableHeader
            title={"Products Specefication"}
            link={"/productSpecification/add"}
          />
          <div>
            {err === 404 ? <h1>No Data</h1> : <ProductTable data={products} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Productspe;
