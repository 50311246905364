import React, { useRef } from 'react';
import './roles.scss';
import { useDispatch } from 'react-redux';
import { updateRole } from '../../../../../RoleApi/roleSlice';
const UpdateRoles = () => {
  const name = useRef(null);
  const prem = useRef(null);

  const dispatch = useDispatch();
  const PermissionRoles = [
    {
      id: 1,
      name: 'Permission1',
    },
    {
      id: 2,
      name: 'Permission1',
    },
    {
      id: 3,
      name: 'Permission1',
    },
    {
      id: 4,
      name: 'Permission1',
    },
    {
      id: 5,
      name: 'Permission1',
    },
    {
      id: 6,
      name: 'Permission1',
    },
    {
      id: 7,
      name: 'Permission1',
    },
    {
      id: 8,
      name: 'Permission1',
    },
    {
      id: 9,
      name: 'Permission1',
    },
  ];
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: name.current.value,
      description: prem.current.value,
    };
    dispatch(updateRole(data));
    name.current.value = '';
    prem.current.value = '';
  };
  return (
    <form className="container" onSubmit={handleSubmit}>
      <div className="input-container">
        <span>Name:</span>
        <input type="text" placeholder="Name" ref={name} />
      </div>
      <span className="spanPer">Permissions</span>
      <div className="per">
        {PermissionRoles.map((role, i) => (
          <div className="check">
            <input
              type="checkbox"
              name="per"
              id=""
              value={role.name}
              ref={prem}
            />
            <span key={i}>{role.name}</span>
          </div>
        ))}
      </div>
      <div>
        <button>Save</button>
      </div>
    </form>
  );
};

export default UpdateRoles;
