import React, { useEffect, useState } from "react";
import "./UpdateNew.scss";
import SideBar from "../../../../Components/SideBar/SideBar";
import Header from "../../../../Components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { getNewById, UpdateNewApi } from "../../../../api/newsApi/New.js";
import { useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import Select from "react-select";
import { Countries } from "../../../../api/CountryApi";
import { getallLang } from "../../../../api/LanguageApi/Lang";

const UpdateNew = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const alert = useAlert();
  const [productLocation, setProductLocation] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const [img, setImg] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [archived, setArchived] = useState("");
  const [massage, setMassage] = useState("");

  useEffect(() => {
    dispatch(getNewById(id));
  }, [dispatch]);
  const data = useSelector((state) => state.news.new);
  // console.log(data);
  useEffect(() => {
    if (data) {
      setTitle(data[0]?.title);
      setContent(data[0]?.content);
      setArchived(data[0]?.archieved ? 1 : 0);
      setImg(data[0]?.image);
      setSelectedOption({
        value: data[0]?.lang?.id,
        label: data[0]?.lang?.name,
      });
      setProductLocation({
        label: data[0]?.location,
      });
    }
  }, [data]);
  const handleSumbit = (e) => {
    e.preventDefault();
    const data = {
      title: title,
      image: img,
      content: content,
      archieved: archived,
      language_id: selectedOption?.value,
      location: productLocation?.value, // corrected name of property
    };
    // console.log(data)
    dispatch(UpdateNewApi({ id: id, newData: data }))
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        alert.show("Sucessful Updated");
        console.log(originalPromiseResult);
        dispatch(getallLang());
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        alert.show(rejectedValueOrSerializedError?.response?.data?.message);

        // alert(rejectedValueOrSerializedError.response.data.message);
        console.log(rejectedValueOrSerializedError);
      });
  };
  const langs = useSelector((state) => state?.Lang?.lang?.langs);
  const options = langs?.map((language) => ({
    value: language.id,
    label: language.name,
  }));
  const optionsCoun = Countries?.map((item) => ({
    value: item.code,
    label: item.name,
  }));
  useEffect(() => {
    dispatch(getallLang());
  }, [dispatch]);

  return (
    <div className="newContainer">
      <SideBar />
      <div className="NewWrapper">
        <Header title={"Add News"} />
        <div className="newBody">
          <form action="" className="addNewForm" onSubmit={handleSumbit}>
            <h1>Update News</h1>
            <div style={{ width: "20vw" }}>
              <p>Language</p>
              <Select
                defaultValue={selectedOption}
                value={selectedOption}
                onChange={setSelectedOption}
                options={options}
              />
            </div>
            <div style={{ width: "20vw" }}>
              <p>Country</p>
              <Select
                value={productLocation}
                defaultValue={productLocation}
                onChange={setProductLocation}
                options={optionsCoun}
              />
            </div>
            <div className="newInputFile">
              <input
                type="file"
                name="image"
                id=""
                // value={}
                onChange={(e) => {
                  setImg(e.target.files[0]);
                }}
              />
            </div>
            <div className="newInput">
              <span>title</span>
              <input
                type="text"
                placeholder="name@example"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="newInput">
              <span>Content</span>
              <input
                className="contentNew"
                type="text"
                value={content}
                placeholder="Add Content"
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
            <div className="newCheckBox">
              <input
                type="checkbox"
                checked={archived}
                value={archived}
                onChange={(e) => setArchived(archived ? 0 : 1)}
              />
              <span>Archived</span>
            </div>
            <button className="buttonSave">Save</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateNew;
