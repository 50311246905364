import '../Products/DelteProduct/DeleteProdcut.scss';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  deleteContact,
  getAllContacts,
} from '../../../api/ContactUsSlice/ContctUsSlice.js';
const DeleteContact = ({ showWarning, setShowWarning, id }) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    // console.log(id);
    dispatch(deleteContact(id)).then(() => {
      dispatch(getAllContacts());
    });
    setShowWarning(!showWarning);
  };
  return (
    <div>
      <div className={`${showWarning ? 'warning' : 'hidden'} `}>
        <div className="war-header">
          <p>Confirm Delete</p>
        </div>
        <div className="bottom">
          <div>
            <p>Are You Sure You Want Delete this?</p>
          </div>
          <div className="btns">
            <button onClick={handleDelete}>Yes</button>
            <button onClick={() => setShowWarning(!showWarning)}>No</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteContact;
