import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import User from "./Pages/Admin/Users/Users";
import Email from "./Pages/Admin/Email/Email";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store";
import { persistStore } from "redux-persist";
import Settings from "./Pages/Admin/Settings/Settings";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
} from "react-router-dom";
import About from "./Pages/WCM/About/About";
import News from "./Pages/WCM/News/News";
import EmailWCM from "./Pages/WCM/Email/EmailWCM";
import Products from "./Pages/WCM/Products/Products";
import Category from "./Pages/WCM/Categories/Category";
import Partners from "./Pages/WCM/Partners/Partners";
import Contact from "./Pages/WCM/Contact/Contact";
import Location from "./Pages/WCM/Location/Location";
import Login from "./Pages/LogIn/Login";
import AddUser from "./Pages/Admin/Users/Table/AddUser/AddUser";
import EditUser from "./Pages/Admin/Users/Table/Edit/EditUser";
import Role from "./Pages/Admin/Role/Role";
import RolePermission from "./Pages/Admin/Role/RolePermission/RolePermission";
import CategoryAdd from "./Pages/WCM/Categories/CategoryAdd/CategoryAdd";
import { Provider } from "react-redux";
import AddProduct from "./Pages/WCM/Products/AddProduct/AddProduct";
import Demo from "./Demo";
import UpdateRoles from "./Pages/Admin/Role/RolePermission/Roles/UpdateRoles";
import AddPartner from "./Pages/WCM/Partners/AddPartner/AddPartner";
import AddClient from "./Pages/WCM/Partners/AddClient/AddClient";
import UpdateAbout from "./Pages/WCM/About/UpdateAbout/UpdateAbout";
import AddNew from "./Pages/WCM/News/AddNew/AddNew";
import UpdateNew from "./Pages/WCM/News/UpdateNew/UpdateNew";
import UpdateProduct from "./Pages/WCM/Products/UpdateProduct/UpdateProduct";
import CategoryUpdate from "./Pages/WCM/Categories/CategoryUpdate/CategoryUpdate";
import UpdatePartner from "./Pages/WCM/Partners/updatePartner/UpdateParter";
import UpdateClients from "./Pages/WCM/Partners/updateClients/UpdateClients";
import AddLocation from "./Pages/WCM/Location/AddLocation/AddLocation";
import UpdateLocation from "./Pages/WCM/Location/UpdateLocation/UpdateLocation";
import Productspe from "./Pages/WCM/ProductSpce/Productspe";
import AddProductSpe from "./Pages/WCM/ProductSpce/addProductSpe/AddProductSpe";
import Language from "./Pages/WCM/Language/Language";
import AddAbout from "./Pages/WCM/About/AddAbout";
const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "role",
    element: <Role />,
    // errorElement: <ErrorBoundary />,
  },
  {
    path: "UpdateRole",
    element: <UpdateRoles />,
  },
  {
    path: "rolePermission",
    element: <RolePermission />,
  },
  {
    path: "adminUser",
    element: <User />,
  },
  {
    path: "aboutUsAdd",
    element: <AddAbout />,
  },
  {
    path: "aboutUsUpdate/:id",
    element: <UpdateAbout />,
  },
  {
    path: "add",
    element: <AddUser />,
  },
  {
    path: "edit",
    element: <EditUser />,
  },
  {
    path: "adminEmail",
    element: <Email />,
  },
  {
    path: "adminSettings",
    element: <Settings />,
  },
  {
    path: "wcmAbout",
    element: <About />,
  },
  {
    path: "wcmNews",
    element: <News />,
  },
  {
    path: "wcmEmail",
    element: <EmailWCM />,
  },
  {
    path: "wcmCategory",
    element: <Category />,
  },
  {
    path: "wcmCategoryAdd",
    element: <CategoryAdd />,
  },
  {
    path: "wcmProducts",
    element: <Products />,
  },
  {
    path: "wcmProductsAdd",
    element: <AddProduct />,
  },
  {
    path: "wcmPartners",
    element: <Partners />,
  },
  {
    path: "addPartners",
    element: <AddPartner />,
  },
  {
    path: "addClients",
    element: <AddClient />,
  },

  {
    path: "wcmContact",
    element: <Contact />,
  },
  {
    path: "updateCategory/:id",
    element: <CategoryUpdate />,
  },
  {
    path: "updatePartner/:id",
    element: <UpdatePartner />,
  },
  {
    path: "updateClient/:id",
    element: <UpdateClients />,
  },
  {
    path: "wcmLocation",
    element: <Location />,
  },
  {
    path: "addNews",
    element: <AddNew />,
  },
  {
    path: "updateNews/:id",
    element: <UpdateNew />,
  },
  {
    path: "updateProduct/:id",
    element: <UpdateProduct />,
  },
  {
    path: "updateLocation/:id",
    element: <UpdateLocation />,
  },
  {
    path: "AddLocation",
    element: <AddLocation />,
  },
  {
    path: "productSpecification",
    element: <Productspe />,
  },
  {
    path: "productSpecification/add",
    element: <AddProductSpe />,
  },
  {
    path: "lang",
    element: <Language />,
  },
]);
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...options}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
        <RouterProvider router={router} />
        {/* <Demo /> */}
        {/* </PersistGate> */}
      </AlertProvider>
    </Provider>
  </React.StrictMode>
);
