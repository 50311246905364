//
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { Link } from "react-router-dom";
// import DeleteCLient from '../DelteClient';
import DeleteLocation from "./DeleteLocation";

export default function BasicTable({ locationData }) {
  const [showWarning, setShowWarning] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const handleDelete = (id) => {
    setSelectedRow(id);
    setShowWarning(!showWarning);
  };
  // console.log(locationData);
  return (
    <div style={{ overflow: "hidden" }}>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>WebSite_URL</TableCell>
              <TableCell>Emails</TableCell>
              {/* <TableCell>Fax</TableCell>
              <TableCell>Langitute</TableCell>
              <TableCell>Latitute</TableCell> */}
              <TableCell>Language</TableCell>
              <TableCell>Country</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {locationData.length > 0 &&
              locationData?.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{++i}</TableCell>
                  <TableCell>{row?.address.substring(0, 20)}</TableCell>
                  <TableCell>{row?.phone}</TableCell>
                  <TableCell>{row?.website_url.substring(0, 20)}</TableCell>
                  <TableCell>{row?.email.substring(0, 20)}</TableCell>
                  {/* <TableCell>{row?.fax}</TableCell>
                  <TableCell>{row?.lang}</TableCell>
                  <TableCell>{row?.lat}</TableCell> */}
                  <TableCell>{row?.language?.name}</TableCell>
                  <TableCell>{row?.location}</TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      // alignItems: "center",
                      justifyItems: "center",
                      gap: "1rem",
                      flexDirection: "column",
                    }}
                  >
                    <Link to={`/updateLocation/${row?.id}`}>
                      <button className="update">Update</button>
                    </Link>
                    <button
                      className="delete"
                      onClick={(e) => handleDelete(row?.id)}
                    >
                      Delete
                    </button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showWarning && (
        <DeleteLocation
          id={selectedRow}
          setShowWarning={setShowWarning}
          showWarning={showWarning}
        />
      )}
    </div>
  );
}
