import React from "react";
import "./warning.scss";
import { useDispatch } from "react-redux";
import { DeleteCategory } from "../../api/CategoryApi/CategorySlice.js";
import { useAlert } from "react-alert";
const Warning = ({ showWarning, setShowWarning, deleteRole, item }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const handleDelete = () => {
    dispatch(DeleteCategory(item))
      .unwrap()
      .then((originalPromiseResult) => {
        alert.show("Sucessful Deleted");
      })
      .catch((rejectedValueOrSerializedError) => {
        alert.show(rejectedValueOrSerializedError?.response?.data?.message);
      });

    setShowWarning(!showWarning);
  };
  return (
    <div className={`${showWarning ? "warning" : "hidden"} `}>
      <div className="war-header">
        <p>Confirm Delete</p>
      </div>
      <div className="bottom">
        <div>
          <p>Are You Sure You Want Delete this?</p>
        </div>
        <div className="btns">
          <button onClick={handleDelete}>Yes</button>
          <button onClick={() => setShowWarning(!showWarning)}>No</button>
        </div>
      </div>
    </div>
  );
};

export default Warning;
