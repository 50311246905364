import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../Consts";
export const getCLient = createAsyncThunk(
  "CLient/getAllCLients",
  async (_, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/Clients`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data);
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);
export const insertCLient = createAsyncThunk(
  "CLient/addCLient",
  async (newData, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(`${BASE_URL}/Clients/store`, newData, {
        headers: {
          "Content-Type": "application/json,multipart/form-data",
          Accept: "application/json",
          authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data);
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);
export const UpdateCLientFun = createAsyncThunk(
  "CLient/updateCLient",
  async ({ id, newData }, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${BASE_URL}/Clients/update/${id}`,
        newData,
        {
          headers: {
            "Content-Type": "application/json,multipart/form-data",
            Accept: "application/json",
            authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response);
      return response.data; // Return the response data
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);
export const deleteCLient = createAsyncThunk(
  "CLient/deleteCLient",
  async (id, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(`${BASE_URL}/Clients/delete/${id}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      // console.log(response);
      return response; // Return the ID of the deleted news item
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);
const CLientStore = createSlice({
  name: "CLients",
  initialState: { CLient: [], loading: false, error: null },
  extraReducers: {
    [getCLient.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getCLient.fulfilled]: (state, action) => {
      state.CLient = action.payload;
      state.loading = false;
    },
    [getCLient.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [UpdateCLientFun.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [UpdateCLientFun.fulfilled]: (state, action) => {
      // Find the index of the updated news item in the news array
      const updatedNewsIndex = state.CLient.CLient.data.findIndex(
        (newsItem) => newsItem.id === action.payload.id
      );
      // console.log(updatedNewsIndex);
      // Replace the old news item with the updated one
      state.CLient[updatedNewsIndex] = action.payload;
      state.loading = false;
    },
    [UpdateCLientFun.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [insertCLient.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [insertCLient.fulfilled]: (state, action) => {
      state.CLient = action.payload;
      state.loading = false;
    },
    [insertCLient.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [deleteCLient.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [deleteCLient.fulfilled]: (state, action) => {
      // Remove the deleted news item from the news array
      state.CLient = state?.CLient?.CLient?.filter(
        (newsItem) => newsItem.id !== action.payload
      );
      state.loading = false;
    },
    [deleteCLient.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});
export default CLientStore.reducer;
