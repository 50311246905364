import React, { useEffect } from "react";
import { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useDispatch } from "react-redux";
import {
  addLang,
  getallLang,
  updateLang,
} from "../../../../api/LanguageApi/Lang";
import { useAlert } from "react-alert";

const AddNewLAng = ({ showAddd, setShowAdd, showUpdate, setUpdate, item }) => {
  const [name, setName] = useState(null);
  const [code, setCode] = useState(null);
  const [native, setNative] = useState(null);
  const dispatch = useDispatch();
  const alert = useAlert();

  const handleClick = () => {
    const data = {
      name,
      code,
      native,
    };
    // console.log(data);
    dispatch(addLang(data))
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        alert.show("Sucessful update");

        dispatch(getallLang());
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        alert.show(rejectedValueOrSerializedError.response.data.message);

        // alert(rejectedValueOrSerializedError.response.data.message);
        console.log(rejectedValueOrSerializedError);
      });
  };
  useEffect(() => {
    if (item) {
      setCode(item?.code);
      setName(item?.name);
      setNative(item?.native);
    }
  }, []);
  const updateData = () => {
    const data = {
      name,
      code,
      native,
    };
    // console.log({ id: item.id, newData: data });
    dispatch(updateLang({ id: item.id, newData: data }))
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        alert.show("Sucessful update");

        dispatch(getallLang());
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        alert.show(rejectedValueOrSerializedError.response.data.message);

        // alert(rejectedValueOrSerializedError.response.data.message);
        console.log(rejectedValueOrSerializedError);
      });
  };
  return (
    <div
      style={{
        width: "55vw",
        height: "50vh",
        backgroundColor: "#fff",
        border: "1px solid black",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        borderRadius: "10px",
        position: "fixed",
        top: "30vh",
        gap: "10px",
      }}
    >
      <button
        onClick={() => (item ? setUpdate(false) : setShowAdd(false))}
        style={{
          right: 8,
          top: 8,
          position: "absolute",
          outline: "none",
          border: "none",
          background: "none",
        }}
      >
        <AiOutlineCloseCircle style={{ width: "1rem", height: "1rem" }} />
      </button>
      <input
        type="text"
        value={name}
        placeholder="Enter Name"
        style={{
          width: "15vw",
          height: "8vh",
          borderRadius: "9px",
          borderWidth: "1px",
          padding: "1rem",
          backgroundColor: "#fff",
          color: "#000",
        }}
        onChange={(e) => setName(e.target.value)}
      />
      <input
        type="text"
        value={code}
        placeholder="Enter Code"
        style={{
          width: "15vw",
          height: "8vh",
          borderRadius: "9px",
          padding: "1rem",
          borderWidth: "1px",
          backgroundColor: "#fff",
          color: "#000",
        }}
        onChange={(e) => setCode(e.target.value)}
      />
      <input
        type="text"
        placeholder="Enter Native"
        value={native}
        style={{
          width: "15vw",
          height: "8vh",
          borderRadius: "9px",
          borderWidth: "1px",
          padding: "1rem",
          backgroundColor: "#fff",
          color: "#000",
        }}
        onChange={(e) => setNative(e.target.value)}
      />
      <button
        onClick={item ? updateData : handleClick}
        style={{
          padding: "1rem 2rem",
          backgroundColor: "#ea5b0c",
          outline: "none",
          border: "none",
          borderRadius: "10px",
          color: "#fff",
        }}
      >
        {item ? "Update" : `Add`}
      </button>
    </div>
  );
};

export default AddNewLAng;
