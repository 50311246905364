import React, { useState } from 'react';
import Header from '../../../../Components/Header/Header';
import SideBar from '../../../../Components/SideBar/SideBar';
import './AddPro.scss';
import { useDispatch } from 'react-redux';
import {
  insertPartner,
  getPartner,
} from '../../../../api/PartnerApi/PartnerSlice';
import { useNavigate } from 'react-router-dom';
const AddPartner = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [image, setImage] = useState([]);
  const [url, setUrl] = useState('');
  const [massage, setMassage] = useState('');

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: name,
      image: image,
      url: url,
    };
    dispatch(insertPartner(data))
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        setMassage(originalPromiseResult.Message);
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log(rejectedValueOrSerializedError);
      });
  };
  // console.log(massage);
  return (
    <div className="AddProductContainer">
      <SideBar />
      <div>
        <Header title={'Partners'} />
        <form onSubmit={handleSubmit} className="Wrapper">
          <h1>Add Partner</h1>
          <div className="inputContainer">
            <div className="nameInput">
              <span>Name</span>
              <input
                type="text"
                placeholder="Product Name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="imgInput">
              <input
                type="file"
                name="brand-image"
                id=""
                onChange={(e) => setImage(e.target.files[0])}
              />
            </div>
            <div className="urlInput">
              <span>Url</span>
              <input
                type="text"
                placeholder="https://www.example.com"
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
          </div>
          <div className="buttonSave">
            <button>Save</button>
          </div>
          {massage ? <span style={{color:"green" , fontSize:"16px", fontWeight:600}}>{massage}</span> : ''}
        </form>
      </div>
    </div>
  );
};

export default AddPartner;
