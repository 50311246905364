import React, { useState } from 'react';
import Header from '../../../../Components/Header/Header';
import SideBar from '../../../../Components/SideBar/SideBar';
import '../AddPartner/AddPro.scss';
import { useDispatch } from 'react-redux';
import { UpdatePartnerFun } from '../../../../api/PartnerApi/PartnerSlice';
import { useParams } from 'react-router-dom';

const UpdatePartner = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [image, setImage] = useState([]);
  const [url, setUrl] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      name: name,
      image: image,
      url: url,
    };
    dispatch(UpdatePartnerFun({ id: id, newData: data }));
    console.log(data);
  };
  return (
    <div className="AddProductContainer">
      <SideBar />
      <div>
        <Header title={'Partners'} />
        <form onSubmit={handleSubmit} className="Wrapper">
          <h1>Update Partner</h1>
          <div className="inputContainer">
            <div className="nameInput">
              <span>Name</span>
              <input
                type="text"
                placeholder="Product Name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="imgInput">
              <input
                type="file"
                name="brand-image"
                id=""
                onChange={(e) => setImage(e.target.files[0])}
              />
            </div>
            <div className="urlInput">
              <span>Url</span>
              <input
                type="text"
                placeholder="https://www.example.com"
                onChange={(e) => setUrl(e.target.value)}
              />
            </div>
          </div>
          <div className="buttonSave">
            <button>Save</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UpdatePartner;
