import React, { useState } from "react";
import "./CategoryItem.scss";
import Laptop from "../../../../images/laptop2";
import Warning from "../../../../Components/warning/Warning";
import { Link } from "react-router-dom";
import { BASE_URL_IMAGE } from "../../../../Consts";
const CategoryItem = ({ data, DeleteCategory }) => {
  const [showWarning, setShowWarning] = useState(false);
  const Img = `${BASE_URL_IMAGE}/${data.image}`;
  const item = data.id;
  // console.log(data);
  return (
    <div className="itemContainer">
      <div className="item-wrapper">
        <div className="itemLeft">
          <p>Product Name : {data.name}</p>
          <p>Product SEO : {data.seo}</p>
          <p>Language : {data?.lang?.name}</p>
          {/* <p>Image:</p>
          <p>Category Type:</p>
          <p>Sub Category:</p> */}
        </div>
        {data.image && (
          <div className="itemRight">
            <img
              src={Img || "https://via.placeholder.com/150"}
              alt={data.name}
            />
          </div>
        )}
      </div>
      <div className="categoryBottom2">
        <Link to={`/updateCategory/${item}`}>
          <button>Update</button>
        </Link>
        <button onClick={() => setShowWarning(!showWarning)}>Delete</button>
      </div>
      <Warning
        showWarning={showWarning}
        setShowWarning={setShowWarning}
        DeleteCategory={DeleteCategory}
        item={item}
      />
    </div>
  );
};

export default CategoryItem;
