import React, { useState } from 'react';
import Warning from '../../../../Components/warning/Warning';
import './table.scss';
import TableContent from './TableContent/TableContent';
import TableHeader from './TableHeader/TableHeader';
const Table = () => {
  const [showWarning, setShowWarning] = useState(false);
  const info = [
    {
      id: 0,
      name: 'Uceiif',
      username: 'Uceiif',
      email: 'ychag@example.com',
      phone: '01126871615',
      role: 'Editor',
      status: 'Active',
      password: '123456789',
    },
    {
      id: 1,
      name: 'ali',
      username: 'ali',
      email: 'ychag@example.com',
      phone: '01126871613235',
      role: 'Editor',
      status: 'Active',
      password: '123sss456789',
    },
    {
      id: 2,
      name: 'salah',
      username: 'salah',
      email: 'ychag@example.com',
      phone: '0112687161dsdas',
      role: 'Editor',
      status: 'Active',
      password: '123dsd456789',
    },
  ];
  return (
    <div className="table-container">
      <TableHeader link="/add" />
      <div className="header-name">
        <p>#</p>
        <div className="item1">
          <p>Name</p>
          <p className="bigger">user_name</p>
          <p className="bigger">Email</p>
        </div>
        <div className="item2">
          <p>Phone</p>
          <p>Role</p>
          <p>Status</p>
          <p>Password</p>
        </div>
      </div>
      {info.map((item, i) => (
        <TableContent
          setShowWarning={setShowWarning}
          showWarning={showWarning}
          key={i}
          item={item}
        />
      ))}

      <Warning showWarning={showWarning} setShowWarning={setShowWarning} />
    </div>
  );
};

export default Table;
