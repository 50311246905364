import React from 'react';
import './tablecontent.scss';
import { Link } from 'react-router-dom';
const TableContent = ({ item, showWarning, setShowWarning }) => {
  return (
    <div className="content-container">
      <div className="infoContent">
        {/* id */}
        <p>{item.id}</p>
        <div className="item3">
          {/* name */}
          <p>{item.name}</p>
          {/* user */}
          <p className="">{item.username}</p>
          {/* gmail */}
          <p className="">{item.email}</p>
        </div>
        <div className="item4">
          {/* Password */}
          <p>{item.password}</p>
          {/* Role */}
          <p>{item.role}</p>
          {/* Status */}
          <p>{item.status}</p>
          {/* Password */}
          <p>{item.password}</p>
        </div>
      </div>
      <div className="btn">
        <Link to="/edit">
          <button>Update</button>
        </Link>
        <button onClick={() => setShowWarning(!showWarning)}>Delete</button>
      </div>
    </div>
  );
};

export default TableContent;
