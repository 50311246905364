import React from 'react';
import './RolePermission.scss';
import SideBar from '../../../../Components/SideBar/SideBar';
import Header from '../../../../Components/Header/Header';
import Roles from './Roles/Roles';
const RolePermission = () => {
  return (
    <div className="role-container">
      <SideBar />
      <div className="role">
        <Header title="Role & Permission" />
        <Roles />
      </div>
    </div>
  );
};

export default RolePermission;
