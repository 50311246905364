import React from "react";
import "./sidebar.scss";
import Logo from "../../images/logo";
import { MdOutlineDashboard } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { useState } from "react";
const SideBar = () => {
  const [show, setShow] = useState(true);
  const [show2, setShow2] = useState(true);

  return (
    <div className="sidebarContainer">
      <div className="imgContainer">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="firstSide">
        <div className="header">
          <div>
            <MdOutlineDashboard />
            <p>Admin Settings</p>
          </div>
          <IoMdArrowDropdown onClick={() => setShow(!show)} />
        </div>
        <div className={`link-container ${show ? "" : "hidden"} `}>
          <NavLink
            to="/role"
            className={({ isActive }) =>
              isActive ? " navLinkActive" : "navLink"
            }
          >
            Role & Permission
          </NavLink>
          <NavLink
            to="/adminUser"
            className={({ isActive }) =>
              isActive ? "navLinkActive" : "navLink"
            }
          >
            Users
          </NavLink>
          <NavLink
            to="/adminEmail"
            className={({ isActive }) =>
              isActive ? "navLinkActive" : "navLink"
            }
          >
            Email Configuration
          </NavLink>
          <NavLink
            to="/adminSettings"
            className={({ isActive }) =>
              isActive ? "navLinkActive" : "navLink"
            }
          >
            Settings
          </NavLink>
        </div>
      </div>
      <div className="SecondSide">
        <div className="header">
          <div>
            <BsFillPersonFill />
            <p>WCM</p>
          </div>
          <IoMdArrowDropdown onClick={() => setShow2(!show2)} />
        </div>
        <div className={`link-container ${show2 ? "" : "hidden2"} `}>
          <NavLink
            to="/wcmAbout"
            className={({ isActive }) =>
              isActive ? "navLinkActive" : "navLink"
            }
          >
            About ME
          </NavLink>
          <NavLink
            to="/wcmNews"
            className={({ isActive }) =>
              isActive ? "navLinkActive" : "navLink"
            }
          >
            News
          </NavLink>
          <NavLink
            to="/wcmEmail"
            className={({ isActive }) =>
              isActive ? "navLinkActive" : "navLink"
            }
          >
            Email Us
          </NavLink>
          <NavLink
            to="/wcmProducts"
            className={({ isActive }) =>
              isActive ? "navLinkActive" : "navLink"
            }
          >
            Products
          </NavLink>
          <NavLink
            to="/productSpecification"
            className={({ isActive }) =>
              isActive ? "navLinkActive" : "navLink"
            }
          >
            Product Specefcations
          </NavLink>
          <NavLink
            to="/wcmCategory"
            className={({ isActive }) =>
              isActive ? "navLinkActive" : "navLink"
            }
          >
            Categories
          </NavLink>
          <NavLink
            to="/wcmPartners"
            className={({ isActive }) =>
              isActive ? "navLinkActive" : "navLink"
            }
          >
            Partners & Clients
          </NavLink>
          <NavLink
            to="/wcmContact"
            className={({ isActive }) =>
              isActive ? "navLinkActive" : "navLink"
            }
          >
            Contact us
          </NavLink>
          <NavLink
            to="/wcmLocation"
            className={({ isActive }) =>
              isActive ? "navLinkActive" : "navLink"
            }
          >
            Location
          </NavLink>
          <NavLink
            to="/lang"
            className={({ isActive }) =>
              isActive ? "navLinkActive" : "navLink"
            }
          >
            Language
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
