import React from "react";
import { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { Link } from "react-router-dom";
import Warning from "../../../../Components/warning/Warning";
import { useDispatch, useSelector } from "react-redux";
import { getallLang, deleteLang } from "../../../../api/LanguageApi/Lang";
import { useEffect } from "react";
import AddNewLAng from "../AddNewLAng/AddNewLAng";
import RemoveITem from "../RemoveITem";
import { useAlert } from "react-alert";
const LangBody = () => {
  const [showWarning, setShowWarning] = useState(false);
  const [showAddd, setShowAdd] = useState(false);
  const [showUpdate, setUpdate] = useState(false);
  const [item, setItem] = useState(null);
  const dispatch = useDispatch();
  const alert = useAlert();

  const data = useSelector((state) => state?.Lang?.lang?.langs);
  useEffect(() => {
    dispatch(getallLang());
  }, [dispatch]);
  const handleClick = (one) => {
    setItem(one);
    setUpdate(!showUpdate);
  };
  const handleDeleteButton = (one) => {
    setShowWarning(!showWarning);
    setItem(one);
  };
  const handleDelete = () => {
    dispatch(deleteLang(item?.id))
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        alert.show("Sucessful Deleted");
        console.log(originalPromiseResult);
        dispatch(getallLang());
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        alert.show(rejectedValueOrSerializedError?.response?.data?.message);

        // alert(rejectedValueOrSerializedError.response.data.message);
        console.log(rejectedValueOrSerializedError);
      });
  };
  return (
    <div className="categoryHeaderContainer">
      <div className="categoryTop">
        <div className="categoryTop2">
          <button onClick={() => setShowAdd(!showAddd)}>Add New</button>
        </div>
        {data?.map((item, i) => (
          <div
            key={i}
            style={{
              width: "70vw",
              height: "20vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="item-wrapper">
              <div className="itemLeft">
                <p>
                  {item?.name} - {item?.native}{" "}
                </p>
              </div>
            </div>
            <div className="categoryBottom2">
              <button onClick={() => handleClick(item)}>Update</button>
              <button onClick={() => handleDeleteButton(item)}>Delete</button>
            </div>
          </div>
        ))}
        {showAddd && <AddNewLAng showAddd={showAddd} setShowAdd={setShowAdd} />}
        {showUpdate && (
          <AddNewLAng
            showUpdate={showUpdate}
            setUpdate={setUpdate}
            item={item}
          />
        )}
        {showWarning && (
          <RemoveITem
            showWarning={showWarning}
            setShowWarning={setShowWarning}
            handleDelete={handleDelete}
            // item={item}
          />
        )}
      </div>
    </div>
  );
};

export default LangBody;
