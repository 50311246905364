import React, { useEffect, useState } from "react";
import "./addNew.scss";
import SideBar from "../../../../Components/SideBar/SideBar";
import Header from "../../../../Components/Header/Header";
import { useDispatch, useSelector } from "react-redux";
import { insertNew } from "../../../../api/newsApi/New";
import { useNavigate } from "react-router-dom";
import { Countries } from "../../../../api/CountryApi";
import { getallLang } from "../../../../api/LanguageApi/Lang";
import Select from "react-select";
import { useAlert } from "react-alert";

const AddNew = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const alert = useAlert();
  const [productLocation, setProductLocation] = useState("");
  const dispatch = useDispatch();
  const [img, setImg] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [archived, setArchived] = useState(0);
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      title: title,
      image: img,
      content: content,
      archieved: archived,
      language_id: selectedOption?.value,
      location: productLocation?.value,
    };
    console.log(data, "====");
    dispatch(insertNew(data))
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        alert.show("Sucessful Added");
        console.log(originalPromiseResult);
        dispatch(getallLang());
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        alert.show(rejectedValueOrSerializedError?.response?.data?.message);

        // alert(rejectedValueOrSerializedError.response.data.message);
        console.log(rejectedValueOrSerializedError);
      });
    // navigate('/wcmNews');
  };
  const langs = useSelector((state) => state?.Lang?.lang?.langs);
  const options = langs?.map((language) => ({
    value: language.id,
    label: language.name,
  }));
  const optionsCoun = Countries?.map((item) => ({
    value: item.code,
    label: item.name,
  }));
  useEffect(() => {
    dispatch(getallLang());
  }, [dispatch]);

  return (
    <div className="newContainer">
      <SideBar />
      <div className="NewWrapper">
        <Header title={"Add News"} />
        <div className="newBody">
          <form action="" className="addNewForm" onSubmit={handleSubmit}>
            <h1>Add News</h1>
            <div style={{ width: "20vw" }}>
              <p>Language</p>
              <Select
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={options}
              />
            </div>
            <div style={{ width: "20vw" }}>
              <p>Country</p>
              <Select
                defaultValue={productLocation}
                onChange={setProductLocation}
                options={optionsCoun}
              />
            </div>

            <div className="newInputFile">
              <input
                type="file"
                name="image"
                id=""
                onChange={(e) => {
                  setImg(e.target.files[0]);
                }}
              />
            </div>
            <div className="newInput">
              <span>title</span>
              <input
                type="text"
                placeholder="name@example"
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="newInput">
              <span>Content</span>
              <input
                className="contentNew"
                type="text"
                placeholder="Add Content"
                onChange={(e) => setContent(e.target.value)}
              />
            </div>
            <div className="newCheckBox">
              <input
                type="checkbox"
                defaultChecked={archived}
                onChange={(e) => setArchived(archived ? 0 : 1)}
              />
              <span>Archived</span>
            </div>
            <button className="buttonSave">Save</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNew;
