import React, { useState } from 'react';
import './roleInfo.scss';
import Warning from '../../../../Components/warning/Warning';
import { Link } from 'react-router-dom';
const RoleInfo = ({ roleInfo, dispatch, deleteRole, token }) => {
  const RoleData = roleInfo.data;
  const [showWarning, setShowWarning] = useState(false);
  return (
    <div className="info-container">
      <div className="titles">
        <p>#</p>
        <p>Name</p>
        <p>Role Name</p>
      </div>

      {RoleData?.map((item, index) => (
        <div key={index} className="role">
          <div className="left">
            <p>{item.id}</p>
            <p>{item.name}</p>
            {/* <p>{item.username}</p> */}
          </div>
          <div className="right">
            <Link to="/UpdateRole">
              <button>Update</button>
            </Link>
            <button onClick={() => setShowWarning(!showWarning)}>Delete</button>
          </div>
          <Warning
            dispatch={dispatch}
            deleteRole={deleteRole}
            showWarning={showWarning}
            setShowWarning={setShowWarning}
            item={item}
          />
        </div>
      ))}
    </div>
  );
};

export default RoleInfo;
