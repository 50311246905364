import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import DeleteCLient from '../DelteClient';
import { BASE_URL_IMAGE } from '../../../../Consts';

export default function BasicTable({ data }) {
  const [showWarning, setShowWarning] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const handleDelete = (id) => {
    setSelectedRow(id);
    setShowWarning(!showWarning);
  };
  return (
    <div>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Url</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>
                  <img
                    src={`${BASE_URL_IMAGE}/${row.image}`}
                    style={{
                      width: '81px',
                      height: '76px',
                      objectFit: 'contain',
                    }}
                    alt=""
                  />
                </TableCell>
                <TableCell>{row.url}</TableCell>
                <TableCell>
                  <Link to={`/updateClient/${row.id}`}>
                    <button className="update">Update</button>
                  </Link>
                </TableCell>
                <TableCell>
                  <button
                    className="delete"
                    onClick={(e) => handleDelete(row.id)}
                  >
                    Delete
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showWarning && (
        <DeleteCLient
          id={selectedRow}
          setShowWarning={setShowWarning}
          showWarning={showWarning}
        />
      )}
    </div>
  );
}
