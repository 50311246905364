import React, { useState } from "react";
import "./ProductTwo.scss";
import Lap from "../../../../../images/laptop2";
import ProgressFullBar from "../ProgressFullBar/ProgressFullBar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch } from "react-redux";
import { addProduct } from "../../../../../api/ProductApi/productSlice";
import { useAlert } from "react-alert";

const ProductTwo = ({ onNext, onPrev, setImages, images, data }) => {
  const [newImages, setNewImages] = useState([]);
  const dispatch = useDispatch();
  const alert = useAlert();
  const handleDispatchSubmit = () => {
    // console.log(data, "===");
    dispatch(addProduct(data))
      .unwrap()
      .then((originalPromiseResult) => {
        // handle result here
        alert.show("Sucessful Added");
        console.log(originalPromiseResult);
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        alert.show(rejectedValueOrSerializedError?.response?.data?.message);

        // alert(rejectedValueOrSerializedError.response.data.message);
      });
  };
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const handleDelete = (index) => {
    setNewImages(newImages.filter((_, i) => i !== index));
  };
  // console.log(newImages);
  const rows = [
    ...newImages.map((image, index) =>
      createData(
        index + 1,
        index,
        <img
          src={URL.createObjectURL(image)}
          style={{ width: "200px", height: "200px", objectFit: "contain" }}
        />,
        null,
        <button className="button2" onClick={() => handleDelete(index)}>
          Delete
        </button>
      )
    ),
  ];

  return (
    <div>
      <div>
        <ProgressFullBar showImage={true} showSpec={false} completed={65} />
      </div>
      <div className="ProductTwoContainer">
        <div className="addinput">
          <input
            type="file"
            required
            multiple
            onChange={(e) => {
              setImages(e.target.files);
              setNewImages([]);
            }}
          />
          <button onClick={() => setNewImages([...newImages, ...images])}>
            Add
          </button>
        </div>
        <div className="">
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell align="right">id</TableCell>
                  <TableCell align="right">Image</TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.calories}</TableCell>
                    <TableCell align="right">{row.fat}</TableCell>
                    <TableCell align="right">{row.carbs}</TableCell>
                    <TableCell align="right">{row.protein}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="backandnext">
          <button onClick={onPrev}>Back</button>
          <button onClick={handleDispatchSubmit}>Next</button>
        </div>
      </div>
    </div>
  );
};

export default ProductTwo;
