import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../Consts";
export const getAllContacts = createAsyncThunk(
  "contactUs/getContact",
  async (_, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/ContactUs`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      // console.log(response.data);
      return response.data;
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error.message);
    }
  }
);
export const deleteContact = createAsyncThunk(
  "contactUs/deleteContact",
  async (id, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.delete(
        `${BASE_URL}/ContactUs/delete/${id}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response);
      return id; // Return the ID of the deleted news item
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

const EmailUsSlice = createSlice({
  name: "contactUs",
  initialState: { contactUs: [], loading: false, error: null },
  extraReducers: {
    [getAllContacts.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getAllContacts.fulfilled]: (state, action) => {
      state.contactUs = action.payload;
      state.loading = false;
    },
    [getAllContacts.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },

    [deleteContact.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [deleteContact.fulfilled]: (state, action) => {
      // Remove the deleted news item from the news array
      state.contactUs = state?.contactUs?.contactUs?.data?.filter(
        (contactItem) => contactItem?.id !== action.payload
      );
      state.loading = false;
    },
    [deleteContact.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});
export default EmailUsSlice.reducer;
