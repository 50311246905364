import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../Consts";
export const getAllCategories = createAsyncThunk(
  "category/getAllCategories",
  async (_, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/Categories`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getCategory = createAsyncThunk(
  "category/getCategory",
  async (id, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(`${BASE_URL}/Categories/edit/${id}`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const getAllCategoriesWithChildren = createAsyncThunk(
  "category/getAllCategoriesWithChildren",
  async (_, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${BASE_URL}/Categories/category-children`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const DeleteCategory = createAsyncThunk(
  "category/deleteCategories",
  async (id, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const res = await axios.delete(`${BASE_URL}/Categories/delete/${id}`, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      // console.log(res);
      return id;
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const AddCategory = createAsyncThunk(
  "category/AddAllCategories",
  async (data, thunkApi) => {
    console.log("====================================");
    console.log(data);
    console.log("====================================");
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const res = await axios.post(`${BASE_URL}/Categories/store`, data, {
        headers: {
          "Content-Type": "application/json,multipart/form-data",
          Accept: "application/json",

          authorization: `Bearer ${token}`,
        },
      });
      // console.log(res.data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "category/updateCategory",
  async ({ id, newData }, thunkApi) => {
    const { rejectWithValue, getState } = thunkApi;
    const token = localStorage.getItem("token");
    try {
      const res = await axios.post(
        `${BASE_URL}/Categories/update/${id}`,
        newData,
        {
          headers: {
            "Content-Type": "application/json,multipart/form-data",
            Accept: "application/json",

            authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(res);
      return res.data; // Return the response data
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState: {
    category: [],
    categoryWithChild: [],
    oneCatgory: [],
    loading: false,
    error: null,
  },
  extraReducers: {
    [getAllCategories.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getAllCategories.fulfilled]: (state, action) => {
      state.category = action.payload;
      state.loading = false;
    },
    [getAllCategories.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getCategory.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getCategory.fulfilled]: (state, action) => {
      state.oneCatgory = action.payload;
      state.loading = false;
    },
    [getCategory.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [getAllCategoriesWithChildren.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [getAllCategoriesWithChildren.fulfilled]: (state, action) => {
      state.categoryWithChild = action.payload;
      state.loading = false;
    },
    [getAllCategoriesWithChildren.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [AddCategory.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [AddCategory.fulfilled]: (state, action) => {
      state.category = action.payload;
      state.loading = false;
    },
    [AddCategory.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [DeleteCategory.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [DeleteCategory.fulfilled]: (state, action) => {
      // Remove the deleted news item from the news array
      state.category = state?.category?.category?.data?.filter(
        (categoryItem) => categoryItem.id !== action.payload
      );
      state.loading = false;
    },
    [DeleteCategory.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    [updateCategory.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    [updateCategory.fulfilled]: (state, action) => {
      state.category.oneCatgory = action.payload;
      state.loading = false;
    },
    [updateCategory.rejected]: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export default categorySlice.reducer;
