import React from "react";
import "./deleteEmail.scss";
import { useDispatch } from "react-redux";
import {
  deleteEmail,
  getAllEmails,
} from "../../../../api/EmailWcmApi/EmailApi.js";
import { useAlert } from "react-alert";

const DeleteEmail = ({ showWarning, setShowWarning, id }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const handleDelete = () => {
    dispatch(deleteEmail(id))
      .unwrap()
      .then((originalPromiseResult) => {
        alert.show("Sucessful Deleted");
        dispatch(getAllEmails());
      })
      .catch((rejectedValueOrSerializedError) => {
        alert.show(rejectedValueOrSerializedError?.response?.data?.message);
      });
    setShowWarning(!showWarning);
  };
  return (
    <div className={`${showWarning ? "warning" : "hidden"} `}>
      <div className="war-header">
        <p>Confirm Delete</p>
      </div>
      <div className="bottom">
        <div>
          <p>Are You Sure You Want Delete this?</p>
        </div>
        <div className="btns">
          <button onClick={handleDelete}>Yes</button>
          <button onClick={() => setShowWarning(!showWarning)}>No</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteEmail;
